import React, { useContext, useEffect, useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import ValidationPattern from '../../services/ValidationPattern';
import CustomTextInput from '../../components/common/CustomTextInput';
import { Link } from 'react-router-dom';
import validation from '../../services/ValidationPattern';
import firebaseAuth from '../../hooks/firebaseAuth';
import { NavContext } from '@ionic/react';
import GlobalService from '../../services/GlobalService';
import settings from '../../config/settings';
import LogoutButton from '../../components/common/LogoutButton';
import firebase from 'firebase';

export default function SignupForm() {
  const classes = useStyles();
  let { height, width } = useWindowDimensions();
  let landscape = height > 500;
  let email = new URLSearchParams(window.location.search).get("email");
  const { navigate } = useContext(NavContext);
  const [number, setNumber] = useState<number>();
  const [logindata, setLoginData] = React.useState({
    email: email,
    emailError: 'Enter correct email address',
    password: '',
    passwordError: 'Min 8 characters and at least 1 number',
    check_textInputChange: false,
    isValidUser: true,
    isValidPassword: true, 
    isValidEmail: true, 
    secureTextEntry: true,
    loginError: '',
    signUp: false,
    name: '',
    nameError: 'Number and special character are not allowed',
    isValidName: true,
    number: '',
    numberError: 'Please enter corrent 10 digit number',
    isValidNumber:true,
    showPassword: false,
});

useEffect(()=>{
  const res = firebase.auth().onAuthStateChanged(async (firebaseUser:any) => {
    setNumber(firebaseUser.phoneNumber);
  });
    // Unsubscribe from events when no longer in use
    return res;
  }, []);

const checkEmail = (val:any) =>{
    logindata.email = val.target.value;
    var email = val.target.value;
    if(validation.checkEmail(email)){
        logindata.isValidEmail = true;
        logindata.email = email;
        setLoginData({
            ...logindata
        });
    }else{
        logindata.isValidEmail = false;
        setLoginData({ ...logindata});
    }
    
}

const checkName = (val:any) =>{
    var name = val.target.value;
    if(validation.checkName(name)){
        logindata.isValidName = true;
        logindata.name = name;
        setLoginData({ ...logindata});
    }else{
        logindata.isValidName = false;
        setLoginData({ ...logindata});
    }
}

const checkNumber = (val:any) =>{
    var num = val.target.value;
    if(validation.checkNumber(num) ){
        logindata.isValidNumber = true;
        logindata.password = num;
        setLoginData({ ...logindata});
    }else{
        logindata.isValidNumber = false;
        setLoginData({ ...logindata});
    }
}

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper} style={{marginTop: landscape ? '10px' : '0px'}}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        {landscape ? <Typography component="h1" variant="h5" className={classes.loginHeading} >
          Contact Developer For Validate Details <br />
          Number: {number}
        </Typography> : ''}
        <LogoutButton />
        {/* <form className={classes.form}  noValidate>
        <CustomTextInput error={!logindata.isValidName} 
            errorText={logindata.nameError} required={true} 
            label="Full Name" name="name" autoComplete="name" 
            onchange={checkName} />
         <CustomTextInput error={!logindata.isValidNumber} 
            errorText={logindata.numberError} required={true} 
            label="Number" name="number" type="number" autoComplete="number" 
            onchange={checkNumber} />

          <CustomTextInput error={!logindata.isValidEmail} 
            errorText={logindata.emailError} required={true} 
            label="Email Address" name="email" autoComplete="email" 
            onchange={checkEmail} value={logindata.email} />

          <Grid container>
            <Grid item>
              <Link to="/help" className={classes.link}>
                {"Help"}
              </Link>
            </Grid>
          </Grid>
        </form> */}
      </div>
    </Container>
  );
}


const useStyles = makeStyles((theme) => ({
    loginHeading: {
        color: 'var(--ion-text-color)',
    },
    paper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      color: 'var(--ion-text-color-highlight)',
      backgroundColor: 'var(--ion-color-primary)',
      margin: theme.spacing(3, 0, 2),
      fontWeight: 'bold',
    },
    submit2: {
        color: 'var(--ion-text-color-highlight)',
        backgroundColor: 'var(--ion-color-primary)',
        fontWeight: 'bold',
        margin: 0,
    },
    link: {
        color: 'var(--ion-color-primary)',
    },
  }));