import React, { useState, useEffect } from 'react';
import { db } from '../../App';
import firebase from 'firebase/app';import 'firebase/auth';
import { Banner } from './Banner';
import { BannerArray } from './BannerArray';

interface Banners{
    banners?:BannerArray[];
}

export function useBannerInit():Banners {
  const [banners, setBanners] = useState<Banners>({banners: []}); // Initial empty array of tests 
  useEffect(() => {
    var uid:any = "";
    if(firebase.auth().currentUser){
        uid = firebase.auth().currentUser?.uid;
    }  
    
    const subscriber = db.collection("banner").doc("banner")
    .onSnapshot((doc) => {
        var data:BannerArray[] = [];
        var object:any = doc.data();
        if(object && object.banner){
            data = object.banner;
        }
        if(data && data.length>0){
            for(var i=0; i<data.length; i++){
                data[i].id = i+1;
            }
        }
        const obj = { banners: data };
        setBanners(obj);
    });

    // Unsubscribe from events when no longer in use
    return subscriber;
  }, []);

  return banners;
}
