import { db } from '../../App';
import CommonErrorHandlar from "../../services/CommonErrorHandlar";
import CounterTableService from "../../services/CounterTableService";

function GetUnit(){
    
}

export async function addUnitData(unit:any) {
        var response = false;
        await db.collection("unit").doc("unit").set({
            unit
        })
        .then(() => {
            console.log("Document successfully written!");
            response = true;
        })
        .catch((error) => {
            CommonErrorHandlar.catcherror(error);
        });
        return response;
}

export async function getUnitData() {
    var unit: any;
    
    await db.collection("unit").doc("unit")
    .get()
    .then((doc) =>{
        unit = doc.data();
    }).catch((err)=>{
        CommonErrorHandlar.catcherror(err);
    });
    return await unit;
}


export default { 
    GetUnit,
    addUnitData,
    getUnitData,
}