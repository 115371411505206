import { IonPage, } from '@ionic/react';
import CustomFooter from '../../components/footer/CustomFooter';

import './NotFound.css'

const NotFound: React.FC = () => {
  return (
    <IonPage>
      <p>Not Found</p>
      <CustomFooter />
    </IonPage>
  );
};

export default NotFound;