import settings from "../config/settings";
import { Notification } from "../pages/notification/Notification";
import GlobalService from "./GlobalService";

export default {
    sendNotificationForOrderStatus(orderId:string){
        var postData:any = {};
        postData.function = "order";
        postData.orderId = orderId;
        GlobalService.sendPostRequestWithTokenJson(settings.serverUrl,postData).then((res)=>{
            console.log(res)
        })
    },
    sendNotificationForSubscribe(notification:Notification){
        var postData:any = {};
        postData.function = "topic";
        postData.role = notification.subscriber ? notification.subscriber : 'Customer';
        postData.clickAction = notification.clickAction;
        var messageBody = { title: notification.title, body: notification.body, image: notification.image, sound: 'sound.mp3', android_channel_id: "testchannel1", channel_id: "testchannel1" };
        postData.notificationBody = JSON.stringify(messageBody);
        GlobalService.sendPostRequestWithTokenJson(settings.serverUrl,postData).then((res)=>{
            console.log(res)
        })
    },
    sendNotificationForProduct(productId:string, userId:string){
        var postData:any = {};
        postData.function = "product";
        postData.productId = productId;
        postData.userId = userId;
        GlobalService.sendPostRequestWithTokenJson(settings.serverUrl,postData).then((res)=>{
            console.log(res)
        })
    },
}