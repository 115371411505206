import React, { useContext, useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import CustomTextInput from '../../components/common/CustomTextInput';
import { IonItem, IonLabel, IonRow, IonToggle, NavContext } from '@ionic/react';
import GlobalService from '../../services/GlobalService';
import settings from '../../config/settings';
import {PrimaryCategory} from './PrimaryCategory';
import { db } from '../../App';
import firebase from 'firebase/app';import 'firebase/auth';
import swal from 'sweetalert';
import NativeApi from '../../services/NativeApi';
import CustomLoading from '../../components/loading/CustomLoading';
import FirestoreService from '../../services/FirestoreService';
import CustomButton from '../../components/common/CustomButton';
import CategoryService from './CategoryService';
import { useCategoryInit } from './CategoryData';

export default function PrimaryCategoryForm() {
  const classes = useStyles();
  let { height, width } = useWindowDimensions();
  let landscape = height > 500;
  let key = new URLSearchParams(window.location.search).get("key");
  let uid = "";
  const { navigate } = useContext(NavContext);
  const [loading, setLoading] = useState<boolean>(false);
  const { categories } = useCategoryInit();
  const [category, setPrimaryCategoryData] = React.useState<PrimaryCategory>({ 
    key: key ? key : '',  
    name: key? 'Primary Category Name':'', 
    icon: settings.imageUploadPlaceholder, 
    image: settings.imageUploadPlaceholder,
    error: 'Enter category name',
    stampId: GlobalService.getCurrentDate().getTime()+"",
    subCategories: []
  });

  useEffect(() => {
    const res = firebase.auth().onAuthStateChanged(async (firebaseUser:any) => {
    if(key){    
        var getPrimaryCategorys:PrimaryCategory[] = await CategoryService.getCategoryData();  
        if(getPrimaryCategorys){
            var newPrimaryCategorys = getPrimaryCategorys.filter(function( obj ) {
                return obj.key === key;
            });
            if(newPrimaryCategorys && newPrimaryCategorys.length > 0){
                if(!newPrimaryCategorys[0].icon){
                    newPrimaryCategorys[0].icon = settings.imageUploadPlaceholder;
                }
                if(!newPrimaryCategorys[0].image){
                    newPrimaryCategorys[0].image = settings.imageUploadPlaceholder;
                }
                if(!newPrimaryCategorys[0].subCategories){
                    newPrimaryCategorys[0].subCategories = [];
                }
                setPrimaryCategoryData(newPrimaryCategorys[0]);
            }
        }
    }
  });
    // Unsubscribe from events when no longer in use
    return res;
  }, []);
  
async function submitForm(){
  if(!category || category.name === ''){
      category.errorShow = true;
      setPrimaryCategoryData({ ...category});
  }else{
    if(categories){
        var val:string = GlobalService.capitalizeCamelCase(category.name);
        var uts:PrimaryCategory[] = [];
        if (categories && categories.length > 0){
            if(category.key){
              var newPrimaryCategorys: PrimaryCategory[] = categories.filter(e => e.key != category.key);
                if(newPrimaryCategorys.length>0){
                    uts = newPrimaryCategorys.map(obj => ({...obj}));
                }
                var u:PrimaryCategory = {name: val};
                if(key){u.key = key}else{u.key='k'+GlobalService.getCurrentDate().getTime()};
                if(category.icon && category.icon!=settings.imageUploadPlaceholder){u.icon = category.icon;}
                if(category.image && category.image!=settings.imageUploadPlaceholder){u.image = category.image;}
                u.subCategories = category.subCategories;
                uts.push(u);
            }else{
              var newPrimaryCategorys: PrimaryCategory[] = categories.filter(e => e.name != val);
                if(newPrimaryCategorys.length>0){
                    uts = newPrimaryCategorys.map(obj => ({...obj}));
                }
                var u:PrimaryCategory = {name: val};
                if(key){u.key = key}else{u.key='k'+GlobalService.getCurrentDate().getTime()};
                if(category.icon && category.icon!=settings.imageUploadPlaceholder){u.icon = category.icon;}
                if(category.image && category.image!=settings.imageUploadPlaceholder){u.image = category.image;}
                u.subCategories = category.subCategories;
                uts.push(u);
            }
            
      }else{
          var u:PrimaryCategory = {name: val};
          if(key){u.key = key}else{u.key='k'+GlobalService.getCurrentDate().getTime()};
          if(category.icon && category.icon!=settings.imageUploadPlaceholder){u.icon = category.icon;}
          if(category.image && category.image!=settings.imageUploadPlaceholder){u.image = category.image;}
          u.subCategories = category.subCategories;
          uts.push(u);
      } 
      let res = await CategoryService.addCategoryData(uts && uts.length>0 ? uts : categories);
      if(res){
          GlobalService.showtoast("categoryadded","Primary Category updated","success",undefined,undefined);
      }
    }
  }
}

const setData = (event:any) =>{
  const { name, value } = event.target;
  setPrimaryCategoryData({ ...category, [name]: value ? GlobalService.capitalizeCamelCase(value) : value});
}

const uploadImage = (e: any) => {
      var imageurl = NativeApi.takePicture(0, 0, 0);
      imageurl.then((img) => {
        setLoading(true);
        firebase.auth().onAuthStateChanged(async (firebaseUser: any) => {
          var name = GlobalService.getCurrentDate().getTime()+"";
          var format = ".jpeg";
          var str = img.split("base64")[0];
          if(str.includes("png")){
            format = ".png";
          };
          var path = category.key ? category.key : category.stampId;
          var filaName = GlobalService.getCurrentDate().getTime()+"";
          var res = await FirestoreService.uploadPicture(img, "categories/"+path+"/"+filaName+format);
          setLoading(false);
          if (res) {
              GlobalService.showtoast('image', 'Image uploaded', 'success', undefined, undefined);
              category.image = GlobalService.getFullUrl(res);
              setPrimaryCategoryData({ ...category});
              if(key){
                submitForm();
              }
          } 
        });
      })
  }

  const uploadIcon = (e: any) => {
      var imageurl = NativeApi.takePicture(0, 0, 0);
      imageurl.then((img) => {
        setLoading(true);
        firebase.auth().onAuthStateChanged(async (firebaseUser: any) => {
          var name = GlobalService.getCurrentDate().getTime()+"";
          var format = ".jpeg";
          var str = img.split("base64")[0];
          if(str.includes("png")){
            format = ".png";
          };
          var path = category.key ? category.key : category.stampId;
          var filaName = GlobalService.getCurrentDate().getTime()+"";
          var res = await FirestoreService.uploadPicture(img, "categories/"+path+"/"+filaName+format);
          setLoading(false);
          if (res) {
              GlobalService.showtoast('icon', 'Image uploaded', 'success', undefined, undefined);
              category.icon = GlobalService.getFullUrl(res);
              setPrimaryCategoryData({ ...category});
              if(key){
                submitForm();
              }
          } 
        });
      })
  }
  
  return (
    <Container component="main">
      <CssBaseline />
      {loading ? <CustomLoading /> : ''}
      <div className={classes.paper} style={{marginTop: '20px'}}>
        
        <form className={classes.form}  noValidate>
        <Grid container justify="space-evenly" >    
            <Grid container spacing={3}>
                <Grid item sm={12} xs={12}>
                  <CustomTextInput error={category.errorShow && category.name==''} errorText={category.error} required={true} 
                        label="Primary Category Name" name="name" autoComplete="name" 
                        value={category.name} onchange={setData} />
                </Grid>
                <Grid container>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <Grid item sm={12} xs={12}>
                        { category.image!=settings.imageUploadPlaceholder ? <img src={category.image} style={{maxHeight: '200px', marginTop: '20px'}} /> : <img src={settings.imageUploadPlaceholder} style={{maxHeight: '200px', marginTop: '20px'}} /> }
                        </Grid>
                        <Grid item sm={12} xs={12}>
                            <CustomButton title="Upload Img" onClick={uploadImage} />
                        </Grid> 
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <Grid item sm={12} xs={12}>
                        { category.icon!=settings.imageUploadPlaceholder ? <img src={category.icon} style={{maxHeight: '200px', marginTop: '20px'}} /> : <img src={settings.imageUploadPlaceholder} style={{maxHeight: '200px', marginTop: '20px'}} /> }
                        </Grid>
                        <Grid item sm={12} xs={12}>
                            <CustomButton title="Upload Icon" onClick={uploadIcon} />
                        </Grid> 
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Button
                fullWidth
                    variant="contained"
                    className={landscape ? classes.submit : classes.submit2}
                    onClick={submitForm}
                >
                {key? 'Update': 'Add'} 
                </Button>
            </Grid>
        </Grid>
        </form>
      </div>
    </Container>
  );
}


const useStyles = makeStyles((theme) => ({
    loginHeading: {
        color: 'var(--ion-text-color)',
    },
    container: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'center',
        alignContent: 'flex-start',
    },
    paper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      color: 'var(--ion-text-color-highlight)',
      backgroundColor: 'var(--ion-color-primary)',
      margin: theme.spacing(3, 0, 2),
      fontWeight: 'bold',
    },
    submit2: {
        color: 'var(--ion-text-color-highlight)',
        backgroundColor: 'var(--ion-color-primary)',
        fontWeight: 'bold',
        margin: 0,
    },
    link: {
        color: 'var(--ion-color-primary)',
    },
  }));