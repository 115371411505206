import { Button, Grid, makeStyles } from '@material-ui/core';
import { IonBadge, IonPage, NavContext } from '@ionic/react';
import { useContext, useState } from 'react';
import SideMenu3 from '../../components/menu/SideMenu3';
import swal from 'sweetalert';
import { UseAuthInit } from '../../hooks/firebase/Auth';
import { useProductInit } from './ProductData';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import ProductCard from './ProductCard';
import CustomFab from '../../components/fab/CustomFab';
import { Product } from './Product';
import ProductService from './ProductService';
import { useBrandInit } from '../brand/BrandData';
import FirestoreService from '../../services/FirestoreService';
import settings from '../../config/settings';

const ProductListPage: React.FC = () => {
  const classes = useStyles();
  const { navigate } = useContext(NavContext);
  const { auth } = UseAuthInit();
  const [files, setFiles] = useState<File[]>();
  const id = auth?.userId;
  const { products } = useProductInit();
  const [productList, setProductList] = useState(products);
  let billkey = new URLSearchParams(window.location.search).get("billKey");
  let { height, width } = useWindowDimensions();
  const {brands} = useBrandInit();

  const removeClick = (item:Product) => {
    swal({
      title: item.name,
      text: "Are you sure that you want to delete this product ?",
      icon: "warning",
      dangerMode: true,
      buttons: ["cancel","confirm"],
    })
    .then(async (willDelete) => {
      if (willDelete && products) {
        console.log(item);
        var newProducts = products.filter(function( obj ) {
            return obj.key !== item.key;
        });
        console.log(newProducts);
        if(item.user && item.user.id){
          let rs = await ProductService.updateProductData(newProducts, item.user.id);
          console.log(rs);
          if(rs){
            swal("Deleted!", "Product has been deleted!", "success");
          }else{
            swal("Failed!", "Something is not correct!", "failed");
          }
        }
        
      }
    });
  }

  const searchevent = (e:any) => { 
    const lowercasedInput = e.detail.value.toLowerCase();
    const results = products?.filter((product) => {
      return product.name?.toLowerCase().includes(lowercasedInput) || product.code?.toLowerCase().includes(lowercasedInput) || product.description?.toLowerCase().includes(lowercasedInput) || product.category1?.toLowerCase().includes(lowercasedInput) || product.category2?.toLowerCase().includes(lowercasedInput) || product.category3?.toLowerCase().includes(lowercasedInput) || product.brand?.toLowerCase().includes(lowercasedInput) || product.user?.name?.toLowerCase().includes(lowercasedInput) || product.user?.firmName?.toLowerCase().includes(lowercasedInput);
    });
    setProductList(results);
  }

  return (
    <IonPage>
         <SideMenu3 title="Product" searchevent={searchevent} /> 
          {brands && brands.length>0 ? <div className={classes.main} >
            <Grid container spacing={1}>
                {
                  productList ? productList.map((item, index)=>{
                    return (
                      <Grid item key={item.key} lg={3} sm={6} xs={12} >
                        <ProductCard brands={brands} key={item.key} product={item} editClick={()=>{navigate("/addProduct?key="+item.key+"&uid="+item.user?.id,"forward","push")}} removeClick={()=>{removeClick(item)}} ></ProductCard>
                      </Grid>
                    )
                  }) : products && products.length >0 ? products.map((item, index)=>{
                    return (
                      <Grid item key={item.key} lg={3} sm={6} xs={12} >
                        <ProductCard brands={brands} key={item.key} product={item} editClick={()=>{navigate("/addProduct?key="+item.key+"&uid="+item.user?.id,"forward","push")}} removeClick={()=>{removeClick(item)}} ></ProductCard>
                      </Grid>
                    )
                  }) :<IonBadge>No records found</IonBadge>
                }
            </Grid>
          </div> : ''}
        <CustomFab onclick={()=>{
          navigate("/addProduct","forward","push")
        }} />
    </IonPage>
) ;
};

const useStyles = makeStyles((theme) => ({
  main: {
    marginLeft: '60px', 
    marginTop: '60px', 
    padding: '10px', 
    position: 'relative',
    height: '-webkit-fill-available', 
    overflow: 'auto', 
    width: '-webkit-fill-available',
},
  container: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignContent: 'flex-start',
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
}));

export default ProductListPage;