import { db } from '../../App';
import CommonErrorHandlar from "../../services/CommonErrorHandlar";
import CounterTableService from "../../services/CounterTableService";

function GetPattern(){
    
}

export async function addPatternData(pattern:any) {
        var response = false;
        await db.collection("pattern").doc("pattern").set({
            pattern
        })
        .then(() => {
            console.log("Document successfully written!");
            response = true;
        })
        .catch((error) => {
            CommonErrorHandlar.catcherror(error);
        });
        return response;
}

export async function getPatternData() {
    var pattern: any;
    
    await db.collection("pattern").doc("pattern")
    .get()
    .then((doc) =>{
        pattern = doc.data();
    }).catch((err)=>{
        CommonErrorHandlar.catcherror(err);
    });
    return await pattern;
}


export default { 
    GetPattern,
    addPatternData,
    getPatternData,
}