import React, { useEffect, useState } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { Button, Checkbox, Grid } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import { green, red } from '@material-ui/core/colors';
import settings from '../../config/settings';
import FirestoreService from '../../services/FirestoreService';
import ShareIcon from '@mui/icons-material/Share';
import GlobalService from '../../services/GlobalService';
import { CustomDialog, useDialog } from 'react-st-modal';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      width: '100%',
    },
    ionCard:{
      width: '100%',
    },
    paper: {
      padding: theme.spacing(2),
      margin: '5px',
      width: '100%',
    },
    media: {
      height: 0,
      paddingTop: '56.25%', // 16:9
    },
    avatar: {
      backgroundColor: red[500],
    },
    button:{
      backgroundColor: 'var(--ion-color-primary)',
    },
    large: {
      width: 'auto',
      height: '200px',
      maxHeight: '250px',
      maxWidth: '300px',
      objectFit: 'scale-down',
      padding: '10px',
    },
    box: {
      
    },
    img: {
      margin: 'auto',
      display: 'block',
      maxWidth: '100%',
      maxHeight: '100%',
    },
    share: {
       position: 'absolute', 
       bottom: '20px', 
       right: '20px',
    }
  }),
);

export interface Dimension{
  width: any,
  height: any,
}

export default function BannerCard({banner, removeClick, editClick, onConnect, onSelect, billkey, tagsClick, checked}:any) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Card className={classes.root}>
        <Grid container justify="space-evenly">
          <Grid container spacing={3}>
            <Grid item xs={2} sm={1} lg={1}>
              <CardHeader
                avatar={
                  <IconButton aria-label="settings">
                   {banner.id}
                  </IconButton>
                }
               />
            </Grid>
            <Grid item xs={3} sm={3} lg={2}>
              <CardHeader
                title={banner.name? banner.name : ''}
                subheader={banner.timestamp? banner.timestamp: ''} 
              />
            </Grid>
            <Grid item xs={5} sm={6} lg={8} style={{position: 'relative'}}>
            <a onClick={async () => {GlobalService.viewImage(banner.imageUrl ? banner.imageUrl : '', undefined, undefined)}}>
              <Avatar variant="square" src={banner.imageUrl} alt={banner.name} className={classes.large}>

              </Avatar>
              </a>
            </Grid>
            <Grid item xs={2} sm={2} lg={1}>
              <CardActions disableSpacing>
                <Typography gutterBottom variant="body2">
                   <Button style={{backgroundColor: 'var(--ion-color-primary)', color: 'var(--ion-color-primary-contrast)', position: 'absolute', right: '16px'}} onClick={removeClick}>Remove</Button>
                </Typography>
              </CardActions>
            </Grid>
          </Grid>
        </Grid>
    </Card>
    </div>
  );
}