import React, { useState, useEffect } from 'react';
import { db } from '../App';
import firebase from 'firebase/app';import 'firebase/auth';
import { Counter } from '../interface/Counter';

interface Counters{
    counters?:Counter;
}

export function useCounterInit():Counters {
  const [counters, setCounters] = useState<Counters>({counters: undefined}); // Initial empty array of tests 
  useEffect(() => {
    var uid:any = "";
    if(firebase.auth().currentUser){
        uid = firebase.auth().currentUser?.uid;
    }  
    const subscriber = db.collection("counter")
    .onSnapshot((querySnapshot) => {
        var data:Counter = {};
        querySnapshot.forEach((doc) => {
            data = doc.data();
        });
        var obj = {counters: data}
        setCounters(obj);
    });

    // Unsubscribe from events when no longer in use
    return subscriber;
  }, []);

  return counters;
}
