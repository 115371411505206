import { db } from '../../App';
import settings from '../../config/settings';
import CommonErrorHandlar from "../../services/CommonErrorHandlar";
import CounterTableService from "../../services/CounterTableService";
import { Brand } from './Brand';

function GetBrand(){
    
}

export async function addBrandData(brand:any) {
        var response = false;
        await db.collection("brand").doc("brand").set({
            brand
        })
        .then(() => {
            console.log("Document successfully written!");
            response = true;
        })
        .catch((error) => {
            CommonErrorHandlar.catcherror(error);
        });
        return response;
}

export async function getBrandData() {
    var brand: any;
    
    await db.collection("brand").doc("brand")
    .get()
    .then((doc) =>{
        var data:any = doc.data();
        brand = data.brand;
    }).catch((err)=>{
        CommonErrorHandlar.catcherror(err);
    });
    return await brand;
}

export default { 
    GetBrand,
    addBrandData,
    getBrandData,
}