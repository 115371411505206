import { Redirect, Route, Switch } from 'react-router-dom';
import firebase from 'firebase/app'; import 'firebase/auth'; import 'firebase/storage'; import 'firebase/database'; import 'firebase/messaging';
import {
  IonApp,
  NavContext,
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import '@fontsource/roboto';
import Login from './pages/login/Login';
import Signup from './pages/signup/Signup';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Slide } from 'react-toastify';
import './AppStyle.css';
import DashboardPage from './pages/dashboard/DashboardPage';
import { UseAuthInit, AuthContext } from "./hooks/firebase/Auth";
import NotFound from './pages/not-found/NotFound';
import Approval from './pages/approval/Approval';
import ProductPage from './pages/product/ProductPage';
import ProductListPage from './pages/product/ProductListPage';
import ManufacturerListPage from './pages/manufacturer/ManufacturerListPage';
import ManufacturerPage from './pages/manufacturer/ManufacturerPage';
import { useContext, useEffect, useState } from 'react';
import CategoryListPage from './pages/category/PrimaryCategoryListPage';
import SubCategoryListPage from './pages/category/SubCategoryListPage';
import Sub2CategoryListPage from './pages/category/Sub2CategoryListPage';
import BannerListPage from './pages/banner/BannerListPage';
import BannerPage from './pages/banner/BannerPage';
import UnitPage from './pages/unit/UnitPage';
import UnitListPage from './pages/unit/UnitListPage';
import UserListPage from './pages/user/UserListPage';
import SizeListPage from './pages/size/SizeListPage';
import ColorListPage from './pages/color/ColorListPage';
import PatternListPage from './pages/pattern/PatternListPage';
import BrandListPage from './pages/brand/BrandListPage';
import ColorPage from './pages/color/ColorPage';
import OrderListPage from './pages/order/OrderListPage';
import OrderPage from './pages/order/OrderPage';
import UserProductViewList from './pages/user/UserProductViewList';
import PreRegisteredList from './pages/user/PreRegiseredList';
import UserProfilePage from './pages/user/UserProfilePage';
import SupplierListPage from './pages/user/SupplierListPage';
import UserListPageProductView from './pages/user/UserListPageProductView';
import SupplierListPageProductView from './pages/user/SupplierListPageProductView';
import CustomLoading from './components/loading/CustomLoading';
import CustomWatermark from './components/common/CustomWatermark';
import OrderPdf from './pages/order/OrderPdf';
import BrandPage from './pages/brand/BrandPage';
import MarketingListPage from './pages/marketing/MarketingListPage';
import UserHiddenListPage from './pages/user/UserHiddenListPage';
import SupplierHiddenListPage from './pages/user/SupplierHiddenListPage';
import UserHiddenListPageProductView from './pages/user/UserHiddenListPageProductView';
import SupplierHiddenListPageProductView from './pages/user/SupplierHiddenListPageProductView';
import MarketingProfilePage from './pages/marketing/MarketingProfilePage';
import OrderDateFilter from './pages/order/OrderDateFilter';
import CategoryPage from './pages/category/CategoryPage';
import ProductListPageUser from './pages/product/ProductListPageUser';
import GlobalService from './services/GlobalService';
import LocalStorageService from './services/LocalStorageService';
import NotificationPage from './pages/notification/NotificationPage';
// import JobworkPage from './pages/production/jobwork/JobworkPage';

export const FirebaseConfig = {
  apiKey: "AIzaSyA09qtk3TfWjlxOUFq5A7vJRvsxdwsgm1o",
  databaseURL: 'https://eco-app-3ee66.firebaseio.com',
  authDomain: "eco-app-3ee66.firebaseapp.com",
  projectId: "eco-app-3ee66",
  storageBucket: "eco-app-3ee66.appspot.com",
  messagingSenderId: "291124281839",
  appId: "1:291124281839:web:f16485f9a3b9d9e57e761a",
  measurementId: "G-6NP5HL0K6E"
};
const firebaseApp = firebase.initializeApp(FirebaseConfig);

const db = firebaseApp.firestore()
const fireStorage = firebase.storage();

const messaging = firebase.messaging();

// db.enablePersistence()
//   .catch((err) => {
//     console.log(err);
//     if (err.code === 'failed-precondition') {
//       // Multiple tabs open, persistence can only be enabled
//       // in one tab at a a time.
//       // ...
//     } else if (err.code === 'unimplemented') {
//       // The current browser does not support all of the
//       // features required to enable persistence
//       // ...
//     }
//   });
export { db };
export { fireStorage }
export { messaging }

const App: React.FC = () => {

  const { loading, auth } = UseAuthInit();

  const [token, setToken] = useState<string>();

  const { navigate } = useContext(NavContext);

  if (loading) {
    return <CustomLoading />;
  }

  if(!token && auth && auth.userId){
    messaging.requestPermission().then(()=>{
      return messaging.getToken();
    }).then(async (token)=>{
      setToken(token);
      await db.collection("admins").doc(auth.userId).collection("token").doc("token").set({
          token
      })
    }).catch(err => console.log(err))
  }

  return (
    <IonApp>
      {/* <CustomWatermark /> */}
      <ToastContainer transition={Slide} limit={5} />
      <IonReactRouter>
        <Switch>
          <Route exact path="/" component={auth?.loggedIn ? auth?.status === 'active' ? DashboardPage : Approval : Login}></Route>
          <Route path="/login" component={auth?.loggedIn ? auth?.status === 'active' ? DashboardPage : Approval : Login}></Route>
          <Route path="/signup" component={auth?.loggedIn ? auth?.status === 'active' ? DashboardPage : Approval : Signup}></Route>
          <Route path="/approval" component={auth?.loggedIn ? auth?.status === 'active' ? DashboardPage : Approval : Login}></Route>
          <Route path="/product" component={auth?.loggedIn ? auth?.status === 'active' ? ProductListPage : Approval : Login}></Route>
          <Route path="/productUser" component={auth?.loggedIn ? auth?.status === 'active' ? ProductListPageUser : Approval : Login}></Route>
          <Route path="/addProduct" component={auth?.loggedIn ? auth?.status === 'active' ? ProductPage : Approval : Login}></Route>
          <Route path="/manufacturer" component={auth?.loggedIn ? auth?.status === 'active' ? ManufacturerListPage : Approval : Login}></Route>
          <Route path="/addManufacturer" component={auth?.loggedIn ? auth?.status === 'active' ? ManufacturerPage : Approval : Login}></Route>
          
          <Route path="/unit" component={auth?.loggedIn ? auth?.status === 'active' ? UnitListPage : Approval : Login}></Route>
          <Route path="/size" component={auth?.loggedIn ? auth?.status === 'active' ? SizeListPage : Approval : Login}></Route>
          <Route path="/color" component={auth?.loggedIn ? auth?.status === 'active' ? ColorListPage : Approval : Login}></Route>
          <Route path="/addColor" component={auth?.loggedIn ? auth?.status === 'active' ? ColorPage : Approval : Login}></Route>
          <Route path="/pattern" component={auth?.loggedIn ? auth?.status === 'active' ? PatternListPage : Approval : Login}></Route>
          
          <Route path="/category" component={auth?.loggedIn ? auth?.status === 'active' ? CategoryListPage : Approval : Login}></Route>
          <Route path="/addCategory" component={auth?.loggedIn ? auth?.status === 'active' ? CategoryPage : Approval : Login}></Route>
          <Route path="/subCategory" component={auth?.loggedIn ? auth?.status === 'active' ? SubCategoryListPage : Approval : Login}></Route>
          <Route path="/sub2Category" component={auth?.loggedIn ? auth?.status === 'active' ? Sub2CategoryListPage : Approval : Login}></Route>
          <Route path="/banner" component={auth?.loggedIn ? auth?.status === 'active' ? BannerListPage : Approval : Login}></Route>
          <Route path="/addBanner" component={auth?.loggedIn ? auth?.status === 'active' ? BannerPage : Approval : Login}></Route>
          <Route path="/user" component={auth?.loggedIn ? auth?.status === 'active' ? UserListPage : Approval : Login}></Route>
          <Route path="/brand" component={auth?.loggedIn ? auth?.status === 'active' ? BrandListPage : Approval : Login}></Route>
          <Route path="/addBrand" component={auth?.loggedIn ? auth?.status === 'active' ? BrandPage : Approval : Login}></Route>
          <Route path="/orders" component={auth?.loggedIn ? auth?.status === 'active' ? OrderListPage : Approval : Login}></Route>
          <Route path="/ordersFilter" component={auth?.loggedIn ? auth?.status === 'active' ? OrderDateFilter : Approval : Login}></Route>
          <Route path="/order" component={auth?.loggedIn ? auth?.status === 'active' ? OrderPage : Approval : Login}></Route>
          <Route path="/productViews" component={auth?.loggedIn ? auth?.status === 'active' ? UserProductViewList : Approval : Login}></Route>
          <Route path="/preregistered" component={auth?.loggedIn ? auth?.status === 'active' ? PreRegisteredList : Approval : Login}></Route>
          <Route path="/userProfile" component={auth?.loggedIn ? auth?.status === 'active' ? UserProfilePage : Approval : Login}></Route>
          <Route path="/supplier" component={auth?.loggedIn ? auth?.status === 'active' ? SupplierListPage : Approval : Login}></Route>
          <Route path="/userProductView" component={auth?.loggedIn ? auth?.status === 'active' ? UserListPageProductView : Approval : Login}></Route>
          <Route path="/supplierProductView" component={auth?.loggedIn ? auth?.status === 'active' ? SupplierListPageProductView : Approval : Login}></Route>
          <Route path="/orderPdf" component={auth?.loggedIn ? auth?.status === 'active' ? OrderPdf : Approval : Login}></Route>
          <Route path="/marketing" component={auth?.loggedIn ? auth?.status === 'active' ? MarketingListPage : Approval : Login}></Route>
          <Route path="/marketingProfile" component={auth?.loggedIn ? auth?.status === 'active' ? MarketingProfilePage : Approval : Login}></Route>
          <Route path="/hideUser" component={auth?.loggedIn ? auth?.status === 'active' ? UserHiddenListPage : Approval : Login}></Route>
          <Route path="/hideSupplier" component={auth?.loggedIn ? auth?.status === 'active' ? SupplierHiddenListPage : Approval : Login}></Route>
          <Route path="/hideUserActivity" component={auth?.loggedIn ? auth?.status === 'active' ? UserHiddenListPageProductView : Approval : Login}></Route>
          <Route path="/hideSupplierActivity" component={auth?.loggedIn ? auth?.status === 'active' ? SupplierHiddenListPageProductView : Approval : Login}></Route>
          <Route path="/notification" component={auth?.loggedIn ? auth?.status === 'active' ? NotificationPage : Approval : Login}></Route>
          <Route path="/notFound" component={NotFound}></Route>
          <Redirect to="/notFound" />
        </Switch>
      </IonReactRouter>
    </IonApp>
  );
}

export default App;
