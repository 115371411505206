import { createMuiTheme, InputAdornment, makeStyles, MuiThemeProvider, TextField } from '@material-ui/core'
import React from 'react'

function CustomTextInput({error, errorText, onchange, required, name, label, type, autoComplete, value, readonly, onKeyPress, width, onFocus, onBlur, focused, autofocused}:any) {
    const classes = useStyles();
    if(value || value==''){
      return (
        <TextField
            variant="outlined"
            margin="normal"
            required={required}
            fullWidth
            name={name}
            label={label}
            type={type}
            id={name}
            error={error}
            InputProps={{
              readOnly: readonly,
            }}
            helperText={error ? errorText : ''}
            autoComplete={autoComplete}
            onChange={onchange ? onchange : undefined}
            onKeyPress = {onKeyPress ? onKeyPress : undefined}
            className= {classes.root}
            value = {value}
            onFocus ={onFocus? onFocus : undefined}
            onBlur = {onBlur? onBlur: undefined}
            autoFocus = {autofocused? autofocused : false}
            focused = {focused? focused : false}
            size="small"
          />
      )
    }else{
      return (
        <TextField
            variant="outlined"
            margin="normal"
            required={required}
            fullWidth
            name={name}
            label={label}
            type={type}
            id={name}
            InputProps={{
              readOnly: readonly,
            }}
            error={error}
            helperText={error ? errorText : ''}
            autoComplete={autoComplete}
            onChange={onchange ? onchange : undefined}
            onKeyPress = {onKeyPress ? onKeyPress : undefined}
            className= {classes.root}
            onFocus ={onFocus? onFocus : undefined}
            onBlur = {onBlur? onBlur: undefined}
            autoFocus = {autofocused? autofocused : false}
            focused = {focused? focused : false}
            size="small"
          />
      )
    }
    
}

const useStyles = makeStyles((theme) => ({
    root: {
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
          borderColor: 'var(--ion-color-primary)'
        },
        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
          borderColor: 'var(--ion-color-primary)'
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderWidth: '2px',
            borderColor: 'var(--ion-color-primary)',
        },
        "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
            borderWidth: '2px',
            borderColor: 'var(--ion-color-danger)',
        },
        "& .MuiOutlinedInput-input": {
          color: 'var(--ion-color-primary)'
        },
        "&:hover .MuiOutlinedInput-input": {
          color: 'var(--ion-color-primary)'
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
          color: 'var(--ion-color-primary)'
        },
        "& .MuiInputLabel-outlined": {
          color: 'var(--ion-color-primary)'
        },
        "&:hover .MuiInputLabel-outlined": {
          color: 'var(--ion-color-primary)'
        },
        "& .MuiInputLabel-outlined.Mui-focused": {
          color: 'var(--ion-color-primary)'
        },
        "& .MuiFormLabel-root.Mui-error": {
          color: 'var(--ion-color-danger)'
        }
      }
}));

export default CustomTextInput
