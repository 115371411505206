import React, { useContext, useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import CustomTextInput from '../../components/common/CustomTextInput';
import { IonItem, IonLabel, IonRow, IonToggle, NavContext } from '@ionic/react';
import GlobalService from '../../services/GlobalService';
import settings from '../../config/settings';
import {Banner} from './Banner';
import bannerService from './BannerService';
import { db } from '../../App';
import firebase from 'firebase/app';import 'firebase/auth';
import CustomButton from '../../components/common/CustomButton';
import { Avatar } from '@material-ui/core';
import NativeApi from '../../services/NativeApi';
import FirestoreService from '../../services/FirestoreService';
import { BannerArray } from './BannerArray';
import { useBannerInit } from './BannerData';
import CustomLoading from '../../components/loading/CustomLoading';
import BannerService from './BannerService';


export default function BannerForm({popup, popupEvent}:any) {
  const classes = useStyles();
  let { height, width } = useWindowDimensions();
  let landscape = height > 500;
  let key = new URLSearchParams(window.location.search).get("key");
  let uid = "";
  const [image, setImageData] = React.useState<string>(settings.imageUploadPlaceholder);
  const { navigate } = useContext(NavContext);
  const {banners} = useBannerInit();
  const [u, setUData] = React.useState<string>(); 
  const [loading, setLoading] = useState<Boolean>();
  const [bannerArray, setBannerArrayData] = React.useState<BannerArray>({
    key: "k"+GlobalService.getCurrentDate().getTime(),
    timestamp: GlobalService.getCurrentDate().toLocaleString(),  
    status: "active",
  });

  useEffect(() => {
   
    // Unsubscribe from events when no longer in use
    return ;
  }, []);
  
async function submitForm() {
  if(bannerArray.url){
    if(banners){
      setLoading(true);
      if (banners.some(e => e.key === bannerArray.key)) {
        setLoading(false);
        GlobalService.showtoast("a"+bannerArray.key, 'Upload failed ! Already uploaded', 'error', undefined, undefined);
      }else{
        banners.push(bannerArray);
        var bn = await bannerService.addBannerData(banners);
        setLoading(false);
        GlobalService.showtoast("a"+bannerArray.key, 'Banner Added', 'success', undefined, undefined);
        navigate("/banner","back","pop");
      }
      
    }else {
      setLoading(false);
      GlobalService.showtoast("a"+bannerArray.key, 'Upload failed', 'error', undefined, undefined);
    }
  }else{
    GlobalService.showtoast("a"+bannerArray.key, 'Upload image first', 'warning', undefined, undefined);
  }
}

const setData = (event:any) =>{
  const { name, value } = event.target;
  setUData(value+"");
}

 async function uploadImage()  {
  var imageurl = NativeApi.takePicture(0, 0, 0);
  imageurl.then(async (img) => {
    setLoading(true);
      var format = ".jpeg";
      var str = img.split("base64")[0];
      if(str.includes("png")){
        format = ".png";
      };
      var fileName = GlobalService.getCurrentDate().getTime()+"";
      var path = "banner/"+fileName;
      var res = await FirestoreService.uploadPicture(img, path+format);
      if (res) {
        var blob = await FirestoreService.uploadString(img, path+".txt");
        if (blob) {
          bannerArray.url = path+format;
          bannerArray.blobUrl = path+".txt";
          bannerArray.fileName = fileName;
          bannerArray.format = format;
          bannerArray.imageUrl = BannerService.getBannerUrl(bannerArray);
          setImageData(img);
          setBannerArrayData(bannerArray);
          setLoading(false);
          GlobalService.showtoast(bannerArray.key, 'Image uploaded', 'success', undefined, undefined);
          
        }else {
          setLoading(false);
          GlobalService.showtoast(bannerArray.key, 'Upload failed', 'error', undefined, undefined);
        }
      } else {
        setLoading(false);
        GlobalService.showtoast(bannerArray.key, 'Upload failed', 'error', undefined, undefined);
      }
  })
}

  return (
    <Container component="main">
      <CssBaseline />
      {loading ? <CustomLoading /> : ''}
      <div className={classes.paper} style={{marginTop: '0px'}}>
        
        <form className={classes.form}  noValidate>
        <Grid container justify="space-evenly" >    
            <Grid container spacing={3}>
                <Grid item sm={12} xs={12} style={{marginTop: '20px'}}>
                <Grid container justify="space-evenly" >
                      <Grid item xs={9} sm={8} lg={6}>
                        <a onClick={async () => {GlobalService.viewImage(image, undefined, undefined)}}>
                        <Avatar variant="square" src={image} alt="Product" className={classes.large}>

                        </Avatar>
                        </a>
                      </Grid>
                      <Grid item xs={3} sm={4} lg={6}>
                        <Grid container justify="space-evenly" >
                          <Grid item xs={12} sm={12} lg={12}>
                            <CustomButton title="Upload" onClick={uploadImage} />
                          </Grid>
                          {/* <Grid item xs={12} sm={12} lg={12}>
                              <CustomButton title="Remove" onClick={removeImage} />
                          </Grid> */}
                        </Grid>
                      </Grid>
                    </Grid>
                </Grid>
                {/* <Grid item sm={12} xs={12} style={{marginTop: '20px'}}>
                  <CustomTextInput error={banner.errorShow && !banner.name?.length } 
                        errorText={banner.error} required={true} 
                        label="Banner Name" name="name" autoComplete="name" 
                        value={u ? u : ''} onchange={setData} />
                </Grid> */}
            </Grid>
            <Grid item xs={12}>
            <Button
                fullWidth
                variant="contained"
                className={landscape ? classes.submit : classes.submit2}
                onClick={submitForm}
            >
               {key? 'Update': 'Add'} 
            </Button>
            </Grid>
        </Grid>
        </form>
      </div>
    </Container>
  );
}


const useStyles = makeStyles((theme) => ({
    loginHeading: {
        color: 'var(--ion-text-color)',
    },
    container: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'center',
        alignContent: 'flex-start',
    },
    paper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      position: 'fixed',
    },
    small: {
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
    large: {
      width: 'auto',
      height: '200px',
      maxHeight: '250px',
      maxWidth: '300px',
      objectFit: 'scale-down',
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      color: 'var(--ion-text-color-highlight)',
      backgroundColor: 'var(--ion-color-primary)',
      margin: theme.spacing(3, 0, 2),
      fontWeight: 'bold',
    },
    submit2: {
        color: 'var(--ion-text-color-highlight)',
        backgroundColor: 'var(--ion-color-primary)',
        fontWeight: 'bold',
        margin: 0,
    },
    link: {
        color: 'var(--ion-color-primary)',
    },
  }));