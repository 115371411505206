import React from 'react';

import { IonButton, IonIcon } from '@ionic/react';
import { makeStyles, Theme } from '@material-ui/core';


export default function CustomButton({title, backgroundColor, color, iconPlace, shape, icon, iconSize, onClick, disabled, width}: any){
    return (
        <IonButton style={{width: width}}
            onClick = {onClick}
            disabled = {disabled}
            color = {backgroundColor}
            shape = {shape}
            size = {iconSize}
            className = "ionBtn"
        >
            {title}
        {icon ? <IonIcon slot={iconPlace} icon={icon} size={iconSize}  /> : ''}
      </IonButton>
    )
}


CustomButton.defaultProps = {
    title: 'Button',
    backgroundColor: 'primary',
    color: 'secondary',
    shape: undefined,
    iconSize: 30,
    disabled: false,
    iconPlace: 'icon-only',
    width: 'auto'
}

const useStyles = makeStyles((theme: Theme) => {
    
});
