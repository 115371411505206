import React, { useState, useEffect } from 'react';
import { db } from '../../App';
import firebase from 'firebase/app'; import 'firebase/auth';
import { User } from './User';

interface Users {
  users?: User[];
}

interface userStatus {
  role?: string,
  status?: string,
}

export function useUserInit(): Users {
  const [users, setusers] = useState<Users>({ users: undefined }); // Initial empty array of users 
  useEffect(() => {
    var uid: any = "";
    if (firebase.auth().currentUser) {
      uid = firebase.auth().currentUser?.uid;
    }
    const subscriber = db.collection("users").where("user.role","==","Customer")
      .onSnapshot(async (querySnapshot) => {
        var data: User[] = [];
        var c = 1;  
        await querySnapshot.forEach( async (doc) => {
          var res =  doc.data();
          var obj:User = res.user; 
          if(obj.status=='hide'){
            obj.sn = c;
            data.push(obj);
            c++;
          }
        });
        var sorted = data.slice(0);
        sorted.sort(function(a,b) {
            var x = a.name;
            var y = b.name;
            if(x && y){
                return x < y ? -1 : x > y ? 1 : 0;
            }else{
                return 0;
            }
        });
        if(sorted && sorted.length>0){
          for(var i=0; i < sorted.length; i++){
            sorted[i].sn = i+1;
          }
        }
        const o = { users: sorted };
        setusers(o);
      });
    // Unsubscribe from events when no longer in use
    return subscriber;
  }, []);

  return users;
}

export async function getUserData(id:any) {
  var us:userStatus = {};
  
  await db.collection("users").doc(id).collection("organization").doc("private")
  .get()
  .then((doc) =>{
    var st = doc.data();
    if(st && st.data){
      us.status = st.data.status;
      if(us.status === 'active'){
        us.role = st.data.role;
      }
    }else{
      us.status = 'inactive';
    }
  })
  return await us;
}
