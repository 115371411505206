import { db } from '../../App';
import CommonErrorHandlar from "../../services/CommonErrorHandlar";
import CounterTableService from "../../services/CounterTableService";
import GlobalService from '../../services/GlobalService';

function GetManufacturer(){
    
}

export async function addManufacturerData(manufacturer:any, uid:any) {
        const timestamp = GlobalService.getCurrentDate().getTime();
        var response = false;
        var count = false;
        if(!manufacturer.key){
            manufacturer.key = "k"+timestamp;
            var count = true;
        }
        manufacturer.updatetimestamp = GlobalService.getCurrentDate().toDateString();
        if(!manufacturer.timestamp){
            manufacturer.timestamp = GlobalService.getCurrentDate().toLocaleString();
        }
        await db.collection("manufacturer").doc(manufacturer.key).set({
            manufacturer
        })
        .then(() => {
            console.log("Document successfully written!");
            if(count){
                CounterTableService.addCountTableData("manufacturerCount","auto",undefined,uid);
            }
            response = true;
        })
        .catch((error) => {
            CommonErrorHandlar.catcherror(error);
        });
        return response;
}

export async function getManufacturerData(id:any, uid:any) {
    var manufacturer: any;
    
    await db.collection("manufacturer").doc(id)
    .get()
    .then((doc) =>{
        manufacturer = doc.data();
    }).catch((err)=>{
        CommonErrorHandlar.catcherror(err);
    });
    return await manufacturer;
}

export async function removeManufacturerData(key:any, uid:any) {
    var response = false;
    console.log(key);
    await db.collection("manufacturer").doc(key).delete()
    .then(()=>{
        CounterTableService.addCountTableData("manufacturerCount","minus",undefined,uid);
        response = true;
    }).catch((err)=>{
        CommonErrorHandlar.catcherror(err);
    });
    return response;
}

export default { 
    GetManufacturer,
    addManufacturerData,
    getManufacturerData,
    removeManufacturerData,
}