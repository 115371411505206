import { IonFooter, IonTitle, IonToolbar } from '@ionic/react'
import { Link, Typography } from '@material-ui/core'
import zIndex from '@material-ui/core/styles/zIndex';
import React from 'react'
import settings from '../../config/settings'
import GlobalService from '../../services/GlobalService';
import './CustomFooter.css';

function CustomFooter() {
    return (
        <IonFooter style={{zIndex: 10000}}>
            <IonToolbar className="ion-no-border" color="primary">
                <IonTitle style={{paddingInline: '0px'}}>
                <Typography variant="body2" align="center" className="custom_title">
                    {'Copyright © '}
                    <Link href={settings.website} target="_blank" className="custom_link"  >
                        {settings.websiteShort}
                    </Link>{' '}
                    {GlobalService.getCurrentDate().getFullYear()}
                    {'.'}
                </Typography>
                </IonTitle>
            </IonToolbar>
        </IonFooter>
    )
}

export default CustomFooter
