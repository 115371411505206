import { useState, useEffect } from 'react';

function getDeviceScreenSize() {
  const { innerWidth: width, innerHeight: height } = window;
  var size = 'md';
  var orientation = height > width ? 'portrait' : 'landscape';
  if(width < 576){
    size = 'xs';
  }else if(width >= 576 && width < 768){
      size = 'sm';
  }else if(width >= 768 && width < 992){
    size = 'md';
  }else if(width >= 992 && width < 1200){
    size = 'lg';
  }else if(width >= 1200){
    size = 'xl';
  }
  return {
    size,
    orientation,
    width,
    height,
  };
}

export default function UseDeviceScreenSize() {
  const [deviceScreenSize, setDeviceScreenSize] = useState(getDeviceScreenSize());

  useEffect(() => {
    function handleResize() {
      setDeviceScreenSize(getDeviceScreenSize());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return deviceScreenSize;
}