import { db } from '../../App';
import CommonErrorHandlar from "../../services/CommonErrorHandlar";
import CounterTableService from "../../services/CounterTableService";

function GetColor(){
    
}

export async function addColorData(color:any) {
        var response = false;
        await db.collection("color").doc("color").set({
            color
        })
        .then(() => {
            console.log("Document successfully written!");
            response = true;
        })
        .catch((error) => {
            CommonErrorHandlar.catcherror(error);
        });
        return response;
}

export async function getColorData() {
    var color: any;
    
    await db.collection("color").doc("color")
    .get()
    .then((doc) =>{
        color = doc.data();
    }).catch((err)=>{
        CommonErrorHandlar.catcherror(err);
    });
    return await color;
}


export default { 
    GetColor,
    addColorData,
    getColorData,
}