import {
    Avatar,
    Box,
    Card,
    CardContent,
    Grid,
    Typography
  } from '@material-ui/core';
  import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
  import MoneyIcon from '@material-ui/icons/Money';
  import { red } from '@material-ui/core/colors';
  
  const DashCard = ({text, count, icon, iconSize, color, backgroundColor,image, page, textColor, onclick}:any) => (
    
    <Grid
            item
            lg={4}
            sm={6}
            xl={3}
            xs={12}
          >
    <Card
   
    style={{ height: '100%', marginTop: '10px', width: '300px', backgroundColor: backgroundColor, color: color }}
    >
      <CardContent onClick={onclick}>
        <Grid
          container
          spacing={3}
          style={{ justifyContent: 'space-between' }}
        >
          <Grid item>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="h6"
              
            >
              <a style={{color: textColor}}>{text}</a>
            </Typography>
          </Grid>
          <Grid item>
            {icon}
          </Grid>
        </Grid>
        <Box
          style={{
           
            display: 'flex',
            alignItems: 'center'
          }}
        >
        </Box>
      </CardContent>
    </Card>
    </Grid>
  );

  DashCard.defaultProps = {
    backgroundColor: 'var(--ion-text-color)',
    color: 'var(--ion-text-color-contrast)',
    iconSize: 56,
    icon: '',
    text: 'sample',
    count: '0/0',
    page: "Home",
}
  
  export default DashCard;
  