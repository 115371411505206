import React, { useState, useEffect } from 'react';
import { db } from '../../App';
import firebase from 'firebase/app';import 'firebase/auth';
import { Manufacturer } from './Manufacturer';

interface Manufacturers{
    manufacturers?:Manufacturer[];
}

export function useManufacturerInit(status: Boolean | undefined):Manufacturers {
  const [manufacturers, setFacilities] = useState<Manufacturers>({manufacturers: undefined}); // Initial empty array of tests 
  useEffect(() => {
    var uid:any = "";
    if(firebase.auth().currentUser){
        uid = firebase.auth().currentUser?.uid;
    }  
    if(status === undefined){
      const subscriber = db.collection("manufacturer")
      .onSnapshot((querySnapshot) => {
          const data:Manufacturer[] = [];
          querySnapshot.forEach((doc) => {
              data.push(doc.data().manufacturer);
          });
          
          const obj = { manufacturers: data };
          setFacilities(obj);
      });

      // Unsubscribe from events when no longer in use
      return subscriber;
    }else{
      const subscriber = db.collection("manufacturer").where("manufacturer.status","==",status)
      .onSnapshot((querySnapshot) => {
          const data:Manufacturer[] = [];
          querySnapshot.forEach((doc) => {
              data.push(doc.data().manufacturer);
          });
          
          const obj = { manufacturers: data };
          setFacilities(obj);
      });

      // Unsubscribe from events when no longer in use
      return subscriber;
    }
  }, []);

  return manufacturers;
}
