import React, { useContext, useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import CustomTextInput from '../../components/common/CustomTextInput';
import { IonItem, IonLabel, IonRow, IonToggle, NavContext } from '@ionic/react';
import GlobalService from '../../services/GlobalService';
import settings from '../../config/settings';
import {Color} from './Color';
import colorService from './ColorService';
import { db } from '../../App';
import firebase from 'firebase/app';import 'firebase/auth';
import { useColorInit } from './ColorData';
import { ColorPicker, ColorBox, ColorInput, ColorPalette, ColorButton } from 'material-ui-color';
const ntc = require('ntcjs');

export default function ColorForm({popup, popupEvent}:any) {
  const classes = useStyles();
  let { height, width } = useWindowDimensions();
  let landscape = height > 500;
  const { navigate } = useContext(NavContext);
  const { colors } = useColorInit();
  const [color, setColorData] = React.useState<string>();
  const [colour, setColour] = useState<any>();

  async function submitForm(){
    if(color && colour){
      var uts:Color[] = [];
      if (colors){
        if(colors.some(e => e.name === color)){
          GlobalService.showtoast(color, "Already exist", "error", undefined, undefined);
          return;
        }else{
          if(colors.length>0){
            uts = colors;
          }
          var id:any = colors[colors.length-1] && colors[colors.length-1].id ? colors[colors.length-1].id : 0;
          id = parseInt(id);
          uts.push({name: color, id: ++id, code: colour && colour.hex ? colour.hex : ''});
        }
      }else{
        uts.push({name: color, id: 1});
      } 
      let res = await colorService.addColorData(uts);
      if(res){
        GlobalService.showtoast(color, "Color added", "success", undefined, undefined);
        navigate("/color","back","pop");
      }
    }else{
      GlobalService.showtoast("color", "Select color and enter it's name", "warning", undefined, undefined);
    }
  }
  

const setData = (event:any) =>{
  const { name, value } = event.target;

  setColorData(GlobalService.capitalizeCamelCase(value+""));
}

const handleChange = (newValue: any) => {
  const n_match = ntc.name('#'+newValue.hex);
  setColour(newValue);
  setColorData(n_match[1]);
};


  return (
    <Container component="main">
      <CssBaseline />
      <div className={classes.paper} style={{marginTop: '0px'}}>
        
        <Grid container justify="space-evenly" >    
            <Grid container spacing={3} style={{marginTop: '20px'}}>
                <Grid item sm={6} xs={6}>
                    <IonLabel>Select Color</IonLabel>
                    {/* {palette ? <ColorPalette palette={palette} /> : 'Not found'} */}
                    <ColorBox value={colour} onChange={handleChange} />
                </Grid>
                <Grid item sm={6} xs={6}>
                  <CustomTextInput required={true} 
                        label="Color Name" name="name" autoComplete="name" 
                        value={color ? color : ''} onchange={setData} />
                </Grid>
            </Grid>
            <Grid item xs={12}>
            <Button
                fullWidth
                variant="contained"
                className={landscape ? classes.submit : classes.submit2}
                onClick={submitForm}
            >
                Add
            </Button>
            </Grid>
        </Grid>
      </div>
    </Container>
  );
}


const useStyles = makeStyles((theme) => ({
    loginHeading: {
        color: 'var(--ion-text-color)',
    },
    container: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'center',
        alignContent: 'flex-start',
    },
    paper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      color: 'var(--ion-text-color-highlight)',
      backgroundColor: 'var(--ion-color-primary)',
      margin: theme.spacing(3, 0, 2),
      fontWeight: 'bold',
    },
    submit2: {
        color: 'var(--ion-text-color-highlight)',
        backgroundColor: 'var(--ion-color-primary)',
        fontWeight: 'bold',
        margin: 0,
    },
    link: {
        color: 'var(--ion-color-primary)',
    },
  }));