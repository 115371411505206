import { Button, Grid, makeStyles, Tooltip } from '@material-ui/core';
import { IonBadge, IonPage, IonToggle, NavContext } from '@ionic/react';
import { useContext, useEffect, useState } from 'react';
import SideMenu3 from '../../components/menu/SideMenu3';
import swal from 'sweetalert';
import CustomFab from '../../components/fab/CustomFab';
import { UseAuthInit } from '../../hooks/firebase/Auth';
import UserService from './UserService';
import { useUserInit } from './UserData';
import { DataGrid, GridColDef, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { Category } from './Category';
import { ProductCount } from './ProductCount';
import firebase from 'firebase';
import { useProductCountKeyInit } from './UserProductViewData';

const UserProductViewList: React.FC = () => {
  const classes = useStyles();
  const { navigate } = useContext(NavContext);
  const { auth } = UseAuthInit();
  const id = auth?.userId;
  let { height, width } = useWindowDimensions();
  let landscape = height > 500;
  let key = new URLSearchParams(window.location.search).get("key");
  const{productCounts} = useProductCountKeyInit(key ? key : '');
  const [products, setProducts] = useState<ProductCount[]>();

  useEffect(()=>{
    const fire = firebase.auth().onAuthStateChanged(async (firebaseUser:any) => {
      if(key && !products){
        var prods:ProductCount[] = await UserService.getProductView(key);
        setProducts(prods);
      }
    })
  });

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'Id',
      width: 80,
      sortable: false,
    },
    {
      field: 'name',
      headerName: 'Name',
      width: 250,
      editable: false,
      renderCell: (params: GridRenderCellParams) => {
        return <Tooltip title={params.row.name}><p>{params.row.name}</p></Tooltip>;
      }
    },
    
    {
      field: 'category',
      headerName: 'Category',
      width: 250,
      editable: false,
      renderCell: (params: GridRenderCellParams) => {
        return <Tooltip title={params.row.category}><p>{params.row.category}</p></Tooltip>;
      }
    },
    {
      field: 'count',
      headerName: 'View Count',
      width: 250,
      editable: false,
      renderCell: (params: GridRenderCellParams) => {
        return <Tooltip title={params.row.count}><p>{params.row.count}</p></Tooltip>;
      }
    },
    {
      field: 'lastView',
      headerName: 'Date',
      width: 250,
      editable: false,
      renderCell: (params: GridRenderCellParams) => {
        var viewDate:any = params.row.lastView;
        var date = new Date(1970, 0, 1);
        if(viewDate && viewDate.seconds){
          date.setSeconds(viewDate.seconds);
        }
        return <Tooltip title={date.toDateString()}><p>{date.toDateString()}</p></Tooltip>;
      }
    },
  ]

  return (
    <IonPage>
         <SideMenu3 title="Products List" /> 
          <div className={classes.main} >
            <Grid container justify="space-evenly">
            <div style={{ height: height * 88 / 100, width: '100%' }}>
                  {productCounts && productCounts.length>0 ? <DataGrid
                    rows={productCounts}
                    columns={columns}
                    pageSize={100}
                    density = 'compact'
                    rowsPerPageOptions={[50]}
                    disableSelectionOnClick
                  /> : 
                  products && products.length>0 ? <DataGrid
                    rows={products}
                    columns={columns}
                    pageSize={100}
                    density = 'compact'
                    rowsPerPageOptions={[50]}
                    disableSelectionOnClick
                  />  
                  : <IonBadge color="primary">No records available</IonBadge>}
                </div>
            </Grid>
          </div>
    </IonPage>
) ;
};

const useStyles = makeStyles((theme) => ({
  main: {
    marginLeft: '60px', 
    marginTop: '60px', 
    padding: '10px', 
    position: 'relative',
    height: '-webkit-fill-available', 
    overflow: 'auto', 
    width: '-webkit-fill-available',
},
  container: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignContent: 'flex-start',
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
  submit: {
    color: 'var(--ion-text-color-highlight)',
    backgroundColor: 'var(--ion-color-primary)',
    fontWeight: 'bold',
  },
  submit2: {
      color: 'var(--ion-text-color-highlight)',
      backgroundColor: 'var(--ion-color-primary)',
      fontWeight: 'bold',
  },
}));

export default UserProductViewList;