import { Grid, makeStyles } from '@material-ui/core';
import { IonBadge, IonPage, NavContext } from '@ionic/react';
import { useContext, useState } from 'react';
import SideMenu3 from '../../components/menu/SideMenu3';
import CustomFooter from '../../components/footer/CustomFooter';
import BannerCard from './BannerCard';
import swal from 'sweetalert';
import CustomFab from '../../components/fab/CustomFab';
import { UseAuthInit } from '../../hooks/firebase/Auth';
import BannerService from './BannerService';
import { useBannerInit } from './BannerData';
import { BannerArray } from './BannerArray';

const BannerListPage: React.FC = () => {
  const classes = useStyles();
  const { navigate } = useContext(NavContext);
  const { auth } = UseAuthInit();
  const id = auth?.userId;
  const { banners } = useBannerInit();
  const [bannerList, setBannerList] = useState<BannerArray[]>();

  const removeClick = (item:any) => {
    swal({
      title: "Banner No. "+item.id,
      text: "Are you sure that you want to delete this banner ?",
      icon: "warning",
      dangerMode: true,
      buttons: ["cancel","confirm"],
    })
    .then(async (willDelete) => {
      if (willDelete) {
        var key = item.key;
        if(banners){
          var newbanners = banners.filter(function( obj ) {
              return obj.key !== item.key;
          });
          var bn = await BannerService.addBannerData(newbanners);
          if(bn){
            swal("Success !", "Banner Removed !", "success");
            setBannerList(newbanners);
          }
        }
        // let rs = BannerService.removeBannerData(key, id);
        // rs.then((response)=>{
        //   console.log(response);
        //   if(response){
        //     swal("Deleted!", "Banner has been deleted!", "success");
        //   }else{
        //     swal("Failed!", "Something is not correct!", "failed");
        //   }
        // });
        
      }
    });
  }

  const editClick = (key:any) => {
    navigate("/addBanner?key="+key,"forward","push");
  }

  var tagItems = banners?.map((item, index) =>{
    if(item){
        return (
          <Grid item xs={12} >
            <BannerCard banner={item} removeClick={() => removeClick(item)} />
            </Grid>
        );     
      }
  });

  const searchevent = (e:any) => { 
    
  }

  return (
    <IonPage>
         <SideMenu3 title="Banner" /> 
          <div className={classes.main} >
            <Grid container justify="space-evenly">
              {bannerList?
                bannerList?.length>0 ? bannerList?.map((item, index) =>{
                  if(item){
                      return (
                        <Grid item key={item.key} xs={12} >
                          <BannerCard banner={item} removeClick={() => removeClick(item)} />
                          </Grid>
                      );     
                    }
                }) : <IonBadge color="primary">No records found</IonBadge> : tagItems && tagItems?.length>0 ? tagItems : <IonBadge color="primary">No records available</IonBadge>            
              }
            </Grid>
          </div>
          <CustomFab onclick={()=>{
          navigate("/addBanner","forward","push");
        }} />     
              {/* <TableView /> */}
        
    </IonPage>
) ;
};

const useStyles = makeStyles((theme) => ({
  main: {
    marginLeft: '60px', 
    marginTop: '60px', 
    padding: '10px', 
    position: 'relative',
    height: '-webkit-fill-available', 
    overflow: 'auto', 
    width: '-webkit-fill-available',
},
  container: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignContent: 'flex-start',
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
}));

export default BannerListPage;