import { Grid, makeStyles } from '@material-ui/core';
import { IonBadge, IonPage, NavContext } from '@ionic/react';
import { useContext, useEffect, useState } from 'react';
import SideMenu3 from '../../components/menu/SideMenu3';
import CustomFooter from '../../components/footer/CustomFooter';
import swal from 'sweetalert';
import CustomFab from '../../components/fab/CustomFab';
import { UseAuthInit } from '../../hooks/firebase/Auth';
import CategoryService from './CategoryService';
import { useCategoryInit } from './CategoryData';
import CategoryCard from './CategoryCard';
import GlobalService from '../../services/GlobalService';
import { PrimaryCategory } from './PrimaryCategory';
import { SubCategory } from './SubCategory';

const SubCategoryListPage: React.FC = () => {
  const classes = useStyles();
  const { navigate } = useContext(NavContext);
  const { auth } = UseAuthInit();
  const id = auth?.userId;
  let name = new URLSearchParams(window.location.search).get("name");
  if(name){
    name = decodeURI(name).replace("11nd", "&");
  }
  const { categories } = useCategoryInit();
  const [category, setCategory] = useState<PrimaryCategory>();
  const [subCategoryList, setSubCategoryList] = useState<SubCategory[]>([]);

  useEffect(() => {
    if(!category){
      const categorys = CategoryService.getCategoryData();
      categorys.then((cats:PrimaryCategory[])=>{
        if(cats && cats.length>0){
          const res = cats.filter(obj => {
            return obj.name === name
          });
          if(res && res[0]){
            setCategory(res[0]);
            if(res[0].subCategories){
              var sorted = res[0].subCategories.slice(0);
                sorted.sort(function(a,b) {
                    var x = a.name;
                    var y = b.name;
                    if(x && y){
                        return x < y ? -1 : x > y ? 1 : 0;
                    }else{
                        return 0;
                    }
                });
              setSubCategoryList(sorted);
            }
          }else{
            navigate("/category","back","pop");
          }
        }
        return ;
      }).catch((err)=>{
        return ;
      })
    }else{
      return ;
    }
  }, []);

  const iconClick = (item:any) => {
    swal({
      title: item.name,
      text: "Are you sure that you want to delete this sub category, All the sub categories of this category will be deleted as well ?",
      icon: "warning",
      dangerMode: true,
      buttons: ["cancel","confirm"],
    })
    .then(willDelete => {
      if (willDelete) {
        if(subCategoryList && category && categories){
            if (categories.some(e => e.name === category.name)) {
              var newSubCategories = subCategoryList.filter(function( obj ) {
                  return obj.name !== item.name;
              });
              category.subCategories = newSubCategories;
              updateCategory("removed");
            }else{
              swal("Failed !", "Primary Category already deleted!", "error");
              navigate("/category","back","pop");
            }
        }
      }
    });
  }

  const updateCategory = (status:string) =>{
    if(subCategoryList && category && categories){
    var newCategories = categories.filter(function( obj ) {
        return obj.name !== category.name;
    });
    newCategories.push(category);
      var cat = CategoryService.addCategoryData(newCategories);
      cat.then((res)=>{
          swal("Success !", "Category "+status+" successfully!", "success");
          setCategory(category);
          if(category.subCategories && category.subCategories.length>0){
            var sorted = category.subCategories.slice(0);
            sorted.sort(function(a,b) {
                var x = a.name;
                var y = b.name;
                if(x && y){
                    return x < y ? -1 : x > y ? 1 : 0;
                }else{
                    return 0;
                }
            });
            setSubCategoryList(sorted);
          }else{
            setSubCategoryList([]);
          }
      });
    }
  }

  const cardClick = (item:any) => {
    if(category && categories){
      if (categories.some(e => e.name === category.name)) {
        var n = encodeURI(category.name).replace("&", "11nd");
        var s = encodeURI(item.name).replace("&", "11nd");;
        navigate("/sub2Category?name="+n+"&sub="+s,"forward","push");
      }else{
        swal("Failed !", "Primary Category already deleted!", "error");
        navigate("/category","back","pop");
      }
    }
  }

  const addCategory = () => {
    const value = swal({
        text: 'Enter Sub Category Name For '+category?.name,
        content: {
          element: "input",
          attributes: {
            placeholder: 'Footwear',
            required: true,
          }
        },
        buttons: ["cancel","confirm"]
      });
      value.then((categoryRes):any =>{
        if(categoryRes){
            var val:string = GlobalService.capitalizeCamelCase(categoryRes);
            if(category && categories){
              if (categories.some(e => e.name === category.name)) {
                if(category.subCategories){
                  if (category.subCategories.some(e => e.name === val)) {
                    swal("Failed !", "Category already exist!", "error");
                    return;
                  }else{
                    var sc:SubCategory = {name: val, sub2Categories: []};
                    category.subCategories.push(sc);
                    updateCategory("added");
                  }
                }
              }else{
                swal("Failed !", "Primary Category already deleted!", "error");
                navigate("/category","back","pop");
              }
            }
        }
      });
  }

  const searchevent = (e:any) => { 
    const lowercasedInput = e.detail.value.toLowerCase();
    const results = category?.subCategories?.filter((category) => {
      return category.name.toLowerCase().includes(lowercasedInput);
    });
    if(results){
      setSubCategoryList(results);
    }
  }

  return (
    <IonPage>
         <SideMenu3 title="Sub Category" searchevent={searchevent} /> 
          <div className={classes.main} >
            <Grid container spacing={1}>
              {subCategoryList && subCategoryList.length>0 ?
                 subCategoryList?.map((item, index) =>{
                  if(item){
                      return (
                        <Grid item key={item.name} lg={3} sm={6} xs={12}>
                          <CategoryCard text={item.name} cardClick={()=>{cardClick(item)}} iconClick={()=>{iconClick(item)}} count={item && item.sub2Categories && item.sub2Categories.length ? item.sub2Categories.length : 0} />
                          </Grid>
                      );     
                    }
                }) : <IonBadge color="primary">No records available</IonBadge>            
              }
            </Grid>
          </div>
        <CustomFab onclick={addCategory} /> 
              {/* <TableView /> */}
        
    </IonPage>
) ;
};

const useStyles = makeStyles((theme) => ({
  main: {
    marginLeft: '60px', 
    marginTop: '60px', 
    padding: '10px', 
    position: 'relative',
    height: '-webkit-fill-available', 
    overflow: 'auto', 
    width: '-webkit-fill-available',
},
  container: {
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    alignContent: 'flex-start',
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
}));

export default SubCategoryListPage;