import { Button, Grid, makeStyles } from '@material-ui/core';
import { IonBadge, IonPage, NavContext } from '@ionic/react';
import { useContext, useState } from 'react';
import SideMenu3 from '../../components/menu/SideMenu3';
import CustomFooter from '../../components/footer/CustomFooter';
import swal from 'sweetalert';
import CustomFab from '../../components/fab/CustomFab';
import { UseAuthInit } from '../../hooks/firebase/Auth';
import ColorService from './ColorService';
import { useColorInit } from './ColorData';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { Color } from './Color';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import GlobalService from '../../services/GlobalService';


const ColorListPage: React.FC = () => {
  const classes = useStyles();
  const { navigate } = useContext(NavContext);
  const { auth } = UseAuthInit();
  const id = auth?.userId;
  const { colors } = useColorInit();
  const [colorList, setColorList] = useState<Color[]>();
  let { height, width } = useWindowDimensions();

  const removeClick = (item:any) => {
    swal({
      title: item.name,
      text: "Are you sure that you want to delete this color ?",
      icon: "warning",
      dangerMode: true,
      buttons: ["cancel","confirm"],
    })
    .then(async (willDelete) => {
      if (willDelete) {
        if(colors){
          var newColors = colors.filter(function( obj ) {
              return obj.name !== item.name;
          });
          var u = await ColorService.addColorData(newColors);
          if(u){
            swal("Success !", "Color removed successfully!", "success");
          }
        }
      }
    });
  }

  const addColor = () => {
    navigate("addColor","forward","push");
  }

  const searchevent = (e:any) => { 
    const lowercasedInput = e.detail.value.toLowerCase();
    const results = colors?.filter((color) => {
      return color?.name?.toLowerCase().includes(lowercasedInput);
    });
    setColorList(results);
  }

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 150 },
    {
      field: 'name',
      headerName: 'Name',
      width: 250
    },
    {
      field: 'color',
      headerName: 'Color',
      width: 250,
      renderCell: (params: GridRenderCellParams) => {
        return <p style={{backgroundColor: '#'+params.row.code, width: '100%', padding: '10px'}}>{params.row.code}</p>;
      }
    },
    {
      field: 'remove',
      headerName: 'Remove',
      width: 250,
      renderCell: (params: GridRenderCellParams) => {
        return <Button color="secondary"  variant="contained" onClick={() => {removeClick(params.row)}}>Remove</Button>;
      }
    },
  ];

  return (
    <IonPage>
         <SideMenu3 title="Color" searchevent={searchevent} /> 
          <div className={classes.main} >
            <Grid container justify="space-evenly">
                {colorList || colors ? <div style={{ height: height * 88 / 100, width: '100%' }}>
                    <DataGrid
                    rows={colorList ? colorList : colors ? colors : []}
                    columns={columns}
                    pageSize={100}
                    rowsPerPageOptions={[50]}
                    checkboxSelection
                    disableSelectionOnClick
                  /> 
                </div> : ''}
            </Grid>
          </div>
          <CustomFab onclick={addColor} />     
    </IonPage>
) ;
};

const useStyles = makeStyles((theme) => ({
  main: {
    marginLeft: '60px', 
    marginTop: '60px', 
    padding: '10px', 
    position: 'relative',
    height: '-webkit-fill-available', 
    overflow: 'auto', 
    width: '-webkit-fill-available',
},
  container: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignContent: 'flex-start',
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
}));

export default ColorListPage;