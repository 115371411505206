import React, { useContext, useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { IonBadge, IonCol, IonGrid, IonItem, IonLabel, IonPage, IonRow, IonToggle, NavContext } from '@ionic/react';
import "react-datepicker/dist/react-datepicker.css";
import { AppBar, Box, Divider, Tab, Tabs } from '@material-ui/core';
import DatePicker from "react-datepicker";
import { statsChart } from 'ionicons/icons';
import { type } from 'os';
import SideMenu3 from '../../components/menu/SideMenu3';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import GlobalService from '../../services/GlobalService';

export interface DateFilter{
  start?: Date,
  end?: Date,
} 

const OrderDateFilter: React.FC = () => {
  const classes = useStyles();
  let { height, width } = useWindowDimensions();
  let landscape = height > 500;
  let uid = "";
  const { navigate } = useContext(NavContext);
  const [datFilter, setDateFilter] = React.useState<DateFilter>({
    start: GlobalService.getCurrentDate(),
    end: GlobalService.getCurrentDate(),
  });
  let type = new URLSearchParams(window.location.search).get("type");

  const submitForm = () => {
    if(datFilter.start && datFilter.end){
      datFilter.start.setHours(0);
      datFilter.start.setMinutes(0);
      datFilter.start.setSeconds(1);
      datFilter.end.setHours(23);
      datFilter.end.setMinutes(59);
      datFilter.end.setSeconds(59);
      navigate("/orders?type="+type+"&from="+datFilter.start.getTime()+"&till="+datFilter.end.getTime(),"forward","push");
    }else {
      GlobalService.showtoast('filter', 'Please enter a field', 'warning', undefined, undefined);
    }
    }

  const setPurchaseOrderStartDate = (date: any) => {
    datFilter.start = date;
    setDateFilter({...datFilter});
  }

  const setPurchaseOrderEndDate = (date: any) => {
    datFilter.end = date;
    setDateFilter({...datFilter});
  }

  return (
        <IonPage>
         <SideMenu3 title={type ? type + ' Orders' : 'Orders'} /> 
          <div className={classes.main} >
          <Grid container justify="space-evenly">
                        <Grid item xs={12} sm={6} lg={6} >
                        <IonLabel className={classes.label}>From </IonLabel><br></br>
                        <DatePicker dateFormat="dd/MM/yyyy" className="react-date-picker" selected={datFilter.start} onChange={(date) => setPurchaseOrderStartDate(date)} />
                        </Grid>
                        <Grid item xs={12} sm={6} lg={6} >
                        <IonLabel className={classes.label}>Till </IonLabel><br></br>
                        <DatePicker dateFormat="dd/MM/yyyy" className="react-date-picker" selected={datFilter.end}  onChange={(date) => setPurchaseOrderEndDate(date)} />
                        </Grid>
                        <Grid item xs={12} sm={12} lg={6} style={{ marginTop: '22px'}}>
                        <Button
                            fullWidth
                            variant="contained"
                            style={{margin: '0px !important'}}
                            className={landscape ? classes.submit : classes.submit2}
                            onClick={submitForm}
                        > Get Orders
                        </Button>
                        </Grid>
                        <Grid item xs={12} sm={12} lg={6} style={{ marginTop: '22px'}}></Grid>
                </Grid>
            </div>
        </IonPage>
  );
}

const useStyles = makeStyles((theme) => ({
    main: { 
        marginLeft: '60px', 
        marginTop: '60px', 
        padding: '10px', 
        position: 'relative',
        height: '-webkit-fill-available', 
        overflow: 'auto', 
        width: '-webkit-fill-available',
    },
      container: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'center',
        alignContent: 'flex-start',
      },
  appBarStyle : {
    backgroundColor: 'var(--ion-color-primary)',
    color: 'var(--ion-color-primary-contrast)',
  },
  loginHeading: {
    color: 'var(--ion-text-color)',
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    color: 'var(--ion-text-color-highlight)',
    backgroundColor: 'var(--ion-color-primary)',
    margin: 0,
    fontWeight: 'bold',
  },
  submit2: {
    color: 'var(--ion-text-color-highlight)',
    backgroundColor: 'var(--ion-color-primary)',
    fontWeight: 'bold',
    margin: 0,
  },
  link: {
    color: 'var(--ion-color-primary)',
  },
  ionCard: {
    width: '100%',
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  button: {
    backgroundColor: 'var(--ion-color-primary)',
  },
  label: {
    fontSize: '16px',
  },
  header: {
    fontSize: '18px',
  },
  box: {

  },
  img: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
  },
}));


export default OrderDateFilter;
