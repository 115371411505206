import React, { useState, useEffect, useContext } from 'react';
import firebase from 'firebase/app';import 'firebase/auth';

import CustomButton from './CustomButton';
import { NavContext } from '@ionic/react';
import swal from 'sweetalert';

  interface Auth {
    loggedIn: Boolean;
    userId?: string;
    userData?:any;
  }
  interface AuthInit {
    loading: boolean;
    auth?: Auth;
  }   

export default function LogoutButton({icon, title, shape, color, backgroundColor, navigation}: any) {
    
    const [authInit, setAuthInit] = useState<AuthInit>({ loading: true });
    const { navigate } = useContext(NavContext);
    useEffect(() => {
        return firebase.auth().onAuthStateChanged((firebaseUser:any) => {
            const auth = firebaseUser
              ? { loggedIn: true, userId: firebaseUser.uid, userData: firebaseUser }
              : { loggedIn: false };
            setAuthInit({ loading: false, auth });
          });
    }, []);
    
    var logout = () => {
      swal({
        title: "Logout",
        text: "Are you sure that you want to logout ?",
        icon: "warning",
        dangerMode: true,
        buttons: ["cancel","confirm"],
      })
      .then((willDelete:any) => {
        if (willDelete) {
          firebase.auth()
          .signOut()
          .then(() => {
            navigate("/login", "root", "replace");
          });
        }
      });
    }

    return (
        <div>
        {
            icon ? 
            <CustomButton icon={icon} title={title} shape ={shape} color={color} backgroundColor={backgroundColor} onClick={logout} /> 
            : <CustomButton title={'Logout'} color={color} backgroundColor={backgroundColor} onClick={logout}  /> 
        }
        </div>
    )
}

LogoutButton.defaultProps = {
    icon: undefined,
    title: 'Logout',
    backgroundColor: 'secondary',
}
