import { Grid, makeStyles } from '@material-ui/core';
import { IonBadge, IonPage, NavContext } from '@ionic/react';
import { useContext, useState } from 'react';
import SideMenu3 from '../../components/menu/SideMenu3';
import CustomFooter from '../../components/footer/CustomFooter';
import ManufacturerCard from './ManufacturerCard';
import swal from 'sweetalert';
import CustomFab from '../../components/fab/CustomFab';
import { UseAuthInit } from '../../hooks/firebase/Auth';
import ManufacturerService from './ManufacturerService';
import { useManufacturerInit } from './ManufacturerData';

const ManufacturerListPage: React.FC = () => {
  const classes = useStyles();
  const { navigate } = useContext(NavContext);
  const { auth } = UseAuthInit();
  const id = auth?.userId;
  const { manufacturers } = useManufacturerInit(undefined);
  const [manufacturerList, setManufacturerList] = useState(manufacturers);

  const removeClick = (item:any) => {
    swal({
      title: item.name,
      text: "Are you sure that you want to delete this manufacturer ?",
      icon: "warning",
      dangerMode: true,
      buttons: ["cancel","confirm"],
    })
    .then(willDelete => {
      if (willDelete) {
        var key = item.key;
        let rs = ManufacturerService.removeManufacturerData(key, id);
        rs.then((response)=>{
          if(response){
            swal("Deleted!", "Manufacturer has been deleted!", "success");
          }else{
            swal("Failed!", "Something is not correct!", "failed");
          }
        });
        
      }
    });
  }

  const editClick = (key:any) => {
    navigate("/addManufacturer?key="+key,"forward","push");
  }

  var tagItems = manufacturers?.map((item, index) =>{
    if(item){
        return (
          <Grid item key={item.key} xs={12} >
            <ManufacturerCard test={item} manufacturer={item} removeClick={() => removeClick(item)} editClick={() => editClick(item.key) } />
            </Grid>
        );     
      }
  });

  const searchevent = (e:any) => { 
    console.log(e.detail.value);
    const lowercasedInput = e.detail.value.toLowerCase();
    const results = manufacturers?.filter((manufacturer) => {
      return manufacturer.tradeName.toLowerCase().includes(lowercasedInput) || manufacturer.name?.toLowerCase().includes(lowercasedInput) || manufacturer.address?.toLowerCase().includes(lowercasedInput) || manufacturer.email?.toLowerCase().includes(lowercasedInput) || manufacturer.contact?.toLowerCase().includes(lowercasedInput) || manufacturer.description?.toLowerCase().includes(lowercasedInput);
      // Use the toLowerCase() method to make it case-insensitive
    });
    setManufacturerList(results);
  }

  return (
    <IonPage>
         <SideMenu3 title="Manufacturer" searchevent={searchevent} /> 
          <div className={classes.main} >
            <Grid container justify="space-evenly">
              {manufacturerList?
                manufacturerList?.length>0 ? manufacturerList?.map((item, index) =>{
                  if(item){
                      return (
                        <Grid item key={item.key} xs={12} >
                          <ManufacturerCard test={item} manufacturer={item} removeClick={() => removeClick(item)} editClick={() => editClick(item.key) } />
                          </Grid>
                      );     
                    }
                }) : <IonBadge color="primary">No records found</IonBadge> : tagItems && tagItems?.length>0 ? tagItems : <IonBadge color="primary">No records available</IonBadge>            
              }
            </Grid>
          </div>
        <CustomFab onclick={()=>{
          navigate("/addManufacturer","forward","push");
        }} />
              {/* <TableView /> */}
        
    </IonPage>
) ;
};

const useStyles = makeStyles((theme) => ({
  main: {
    marginLeft: '60px', 
    marginTop: '60px', 
    padding: '10px', 
    position: 'relative',
    height: '-webkit-fill-available', 
    overflow: 'auto', 
    width: '-webkit-fill-available',
},
  container: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignContent: 'flex-start',
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
}));

export default ManufacturerListPage;