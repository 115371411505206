import { db } from '../App';

function GetCountTable(){
    
}

export async function addCountTableData(key:string, value:number|"auto"|"minus", newValue: number|undefined,uid:any) {
        var response = false;
        var counter = getCountTableDataWithKey(uid,key);
        if(value === "auto"){
            value = 1;
            counter.then(async (doc)=>{
                console.log(doc);
                if(doc!=undefined){
                    if(newValue){
                        value = parseInt(doc) + newValue;
                    }else{
                        value = parseInt(doc) + 1;
                    }
                }
                console.log(value);
                await db.collection("counter").doc("counter").update({
                    [key] : value
                })
                .then(() => {
                    console.log("Document successfully written!");
                    response = true;
                }).catch(async ()=>{
                    await db.collection("counter").doc("counter").set({
                        [key] : value
                    })
                })
            })
        }else if(value === "minus"){
            value = 0;
            counter.then(async (doc)=>{
                console.log(doc);
                if(doc!=undefined){
                    if(newValue){
                        value = parseInt(doc) - newValue;
                    }else{
                        value = parseInt(doc) - 1;
                    }
                }
                console.log(value);
                await db.collection("counter").doc("counter").update({
                    [key] : value
                })
                .then(() => {
                    console.log("Document successfully written!");
                    response = true;
                }).catch(async ()=>{
                    await db.collection("counter").doc("counter").set({
                        [key] : value
                    })
                })
            });
        }else{
            await db.collection("counter").doc("counter").update({
                [key] : value
            })
            .then(() => {
                console.log("Document successfully written!");
                response = true;
            }).catch(async ()=>{
                await db.collection("counter").doc("counter").set({
                    [key] : value
                })
            })
        }
        return response;
}

export async function getCountTableData(uid:any) {
    var counter: any;
    
    await db.collection("counter").doc("counter")
    .get()
    .then((doc) =>{
        counter = doc.data();
    }).catch((err)=>{
        
    });
    return await counter;
}

export async function getCountTableDataWithKey(uid:any, key:string) {
    var counter: any;
    
    await db.collection("counter").doc("counter")
    .get()
    .then((doc) =>{
        counter = doc.get(key);
        console.log(counter);
    }).catch((err)=>{
        
    });
    return await counter;
}

export default { 
    GetCountTable,
    addCountTableData,
    getCountTableData,
}