import { Avatar } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useState } from 'react';
import { useDialog } from 'react-st-modal';
import CustomFab from '../fab/CustomFab';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

export default function ImageView({image, alt}:any) {
    const dialog = useDialog();
    const classes = useStyles();

    const [value, setValue] = useState();
  
    const popupEvent = () =>{
      dialog.close(value);
    }
  
    return (
      <div>
        <Avatar variant="square" src={image} alt={alt ? alt : 'Image'} className={classes.large}>

        </Avatar>
        {/* <CustomFab icon={<HighlightOffIcon />} onclick={popupEvent} /> */}
      </div>
    );
  }

  const useStyles = makeStyles((theme: Theme) => ({
    
  large: {
    width: 'auto',
    height: '500px',
    maxHeight: '750px',
    maxWidth: '800px',
    objectFit: 'scale-down',
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: '10px',
  },
  }));