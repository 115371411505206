import { IonPage, } from '@ionic/react';
import CustomFooter from '../../components/footer/CustomFooter';
import ApprovalForm from './ApprovalForm';

import './Approval.css'

const Approval: React.FC = () => {
  

  return (
    <IonPage>
      <ApprovalForm />
      <CustomFooter />
    </IonPage>
  );
};

export default Approval;