import { Container, Grid, makeStyles } from '@material-ui/core';
import { IonPage, NavContext } from '@ionic/react';
import { useContext } from 'react';
import MarketingProfileForm from './MarketingProfileForm';
import SideMenu3 from '../../components/menu/SideMenu3';

const MarketingProfilePage: React.FC = () => {
  const classes = useStyles(); 

  return (
    <IonPage>
         <SideMenu3 title="Marketing Profile" /> 
         <div className={classes.main} >
          <Container maxWidth={false}>
            <MarketingProfileForm />
            </Container>
          </div>
          
    </IonPage>
) ;
};

const useStyles = makeStyles((theme) => ({
    main: {
        marginLeft: '60px', 
        marginTop: '60px', 
        padding: '10px', 
        position: 'relative',
        height: '-webkit-fill-available', 
        overflow: 'auto', 
        width: '-webkit-fill-available',
    },  
  container: {
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    alignContent: 'flex-start',
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
}));

export default MarketingProfilePage;