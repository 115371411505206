
import { db } from '../../App';
import settings from '../../config/settings';
import CommonErrorHandlar from "../../services/CommonErrorHandlar";
import { BannerArray } from './BannerArray';

function GetBanner(){
    
}

export async function addBannerData(banner:any) {
        var response = false;
        await db.collection("banner").doc("banner").set({
            banner
        })
        .then(() => {
            console.log("Document successfully written!");
            response = true;
        })
        .catch((error) => {
            CommonErrorHandlar.catcherror(error);
        });
        return await response;
}

export async function getBannerData() {
    var banner: any;
    await db.collection("banner").doc("banner")
    .get()
    .then((doc) =>{
        banner = doc.data();
    }).catch((err)=>{
        CommonErrorHandlar.catcherror(err);
    });
    return await banner.banner;
}

export async function removeBannerData() {
    var response = false;
    await db.collection("banner").doc("banner").delete()
    .then(()=>{
        response = true;
    }).catch((err)=>{
        CommonErrorHandlar.catcherror(err);
    });
    return response;
}

export function getBannerUrl(bannerArray:BannerArray){
    if(bannerArray.url){
        var url = settings.firestoreUrlPrefix+bannerArray.url.replaceAll("/","%2F")+settings.firestoreUrlSuffix;
        return url;
    }else{
        return '';
    }
}


export default { 
    GetBanner,
    addBannerData,
    getBannerData,
    getBannerUrl,
}