import { Button, Grid, makeStyles } from '@material-ui/core';
import { IonBadge, IonButton, IonLabel, IonPage, NavContext } from '@ionic/react';
import { useContext, useEffect, useState } from 'react';
import { UseAuthInit } from '../../hooks/firebase/Auth';
import React from 'react';
import firebase from 'firebase/app';import 'firebase/auth';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import OrderCard from './OrderCard';
import { useOrderKeyInit } from './OrderDataById';
import SideMenu3 from '../../components/menu/SideMenu3';
import { useBrandInit } from '../brand/BrandData';
import { Order } from './Order';
import OrderService from './OrderService';
import swal from 'sweetalert';
import NotificationService from '../../services/NotificationService';

const OrderPage: React.FC = () => {
  const classes = useStyles();
  const { navigate } = useContext(NavContext);
  const { auth } = UseAuthInit();
  let key = new URLSearchParams(window.location.search).get("key");
  const { order } = useOrderKeyInit(key ? key : '');  
  const {brands} = useBrandInit();

  const approveClick = (item:Order) => {
    swal({
      title: item.id ? item.id+'' : 'Alert',
      text: item.status === 'pending' ? "Are you sure that you want to approve this order ?" : "Are you sure that you want to change this order to pending ?",
      icon: "warning",
      dangerMode: true,
      buttons: ["cancel","confirm"],
    })
    .then(willDelete => {
      if (willDelete) {
        if(item.status === 'pending'){
          item.status = "approved";
        }else{
          item.status = "pending";
        }
        let rs = OrderService.addOrderData(item);
        rs.then((response)=>{
          console.log(response);
          if(response){
            // Update inventory for each product
           swal(item.status ? item.status : 'Status', "Order has been "+item.status+"!", "success");
           NotificationService.sendNotificationForOrderStatus(item.key ? item.key : '');
          }else{
            swal("Failed!", "Something is not correct!", "failed");
          }
        });
        
      }
    });
  }

  const cancelClick = (item:Order) => {
    swal({
      title: item.id ? item.voucherNumber+'' : 'Alert',
      text: item.status != 'cancelled' ? "Are you sure that you want to cancel this order ?" : "Are you sure that you want to change this order to pending state ?",
      icon: "warning",
      dangerMode: true,
      buttons: ["cancel","confirm"],
    })
    .then(willDelete => {
      if (willDelete) {
        if(item.status != 'cancelled'){
          item.status = "cancelled";
        }else{
          item.status = "pending";
        }
        let rs = OrderService.addOrderData(item);
        rs.then((response)=>{
          console.log(response);
          if(response){
            // Update inventory for each product
           swal(item.status ? item.status : 'Status', "Order has been "+item.status+"!", "success");
           console.log("order notification")
           NotificationService.sendNotificationForOrderStatus(item.key ? item.key : '');
          }else{
            swal("Failed!", "Something is not correct!", "failed");
          }
        });
        
      }
    });
  }
  
  return (
    <IonPage>
         <SideMenu3 title="Orders" /> 
         {brands && brands.length> 0 ? <div className={classes.main}>
            {order && order.products && order.products.length>0 ? <Grid container spacing={1}>
                <Grid item key="m2" lg={12} sm={12} xs={12}>
                      <Card sx={{ width: '100%' }}>
                        <CardContent>
                            {order.status === 'pending' ? <IonButton color="primary" onClick={()=>{approveClick(order)}}>Approve Now</IonButton> : ''}
                            {order.status != 'cancelled' && order.status != 'delivered' ? <IonButton color="primary" onClick={()=>{cancelClick(order)}}>Cancel Now</IonButton> : ''}
                            <br></br>
                            <IonBadge style={{margin: '10px', fontSize: '16px'}} color="success">Status: {order.status}</IonBadge>
                            {order.supplierStatus ? <IonBadge style={{margin: '10px', fontSize: '16px'}} color="success">Supplier Status: {order.supplierStatus}</IonBadge> : ''}
                            <IonBadge style={{margin: '10px', fontSize: '16px'}} color="warning">Total WSP: &#8377; {order.total}</IonBadge>
                            <IonBadge style={{margin: '10px', fontSize: '16px'}} color="danger">Items: {order.products.length}</IonBadge>
                            <IonBadge style={{margin: '10px', fontSize: '16px'}} color="primary">Supplier: {order.supplier?.name} | {order.supplier?.firmName}</IonBadge>
                            <IonBadge style={{margin: '10px', fontSize: '16px'}} color="secondary">Customer: {order.user?.name} | {order.user?.firmName}</IonBadge>
                        </CardContent>
                    </Card> 
                </Grid>  
                <Grid item key="m1" lg={12} sm={12} xs={12} >
                   { order.products.map((item, index)=>{
                        return (
                            <Grid item key={item.key+''+index} lg={12} sm={12} xs={12} >
                                <OrderCard brands={brands} key={item.key+'a'+index} product={item}></OrderCard>
                            </Grid>
                        )
                    }) 
                    }
                </Grid>
            </Grid> : 
              <div className={classes.center}>
                <Button onClick={()=>{
                  navigate("/store","forward","push");
                }} style={{color: 'var(--ion-color-primary-white)', backgroundColor: 'var(--ion-color-primary)', marginTop: '10px',}} >Shop Now</Button>
              </div>}
          </div>: ''}
    </IonPage>
) ;
};

const useStyles = makeStyles((theme) => ({
  main: {
    marginLeft: '60px', 
    marginTop: '60px', 
    padding: '10px', 
    position: 'relative',
    height: '-webkit-fill-available', 
    overflow: 'auto', 
    width: '-webkit-fill-available',
},
center: {
  marginLeft: 'auto',
  marginRight: 'auto',
  padding: '10px',
  marginTop: '80px',
  textAlign: 'center'
},
  container: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignContent: 'flex-start',
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
}));

export default OrderPage;