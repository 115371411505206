import { Button, Grid, makeStyles, Tooltip } from '@material-ui/core';
import { IonBadge, IonPage, IonToggle, NavContext } from '@ionic/react';
import { useContext, useState } from 'react';
import SideMenu3 from '../../components/menu/SideMenu3';
import swal from 'sweetalert';
import CustomFab from '../../components/fab/CustomFab';
import { UseAuthInit } from '../../hooks/firebase/Auth';
import UserService from './UserService';
import { useUserInit } from './UserDataFilter';
import { DataGrid, GridColDef, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { Category } from './Category';
import { User } from './User';
import CustomLoading from '../../components/loading/CustomLoading';

const UserHiddenListPage: React.FC = () => {
  const classes = useStyles();
  const { navigate } = useContext(NavContext);
  const { auth } = UseAuthInit();
  const id = auth?.userId;
  const { users } = useUserInit();
  const [userList, setUserList] = useState<User[]>();
  let { height, width } = useWindowDimensions();
  let landscape = height > 500;

  const approveClick = (async (item:any) => {
    // check gst number
    var sts = 'inactive';
    swal({
      title: item.firmName,
      text: "Are you sure that you want to "+sts+" this user ?",
      icon: "warning",
      dangerMode: true,
      buttons: ["cancel","confirm"],
    })
    .then(async (willApprove) => {
      if (willApprove) {
        item.status = sts;
        let rs = await UserService.addUserData(item);
        if(rs){
          let s = await UserService.addUserStatusData(item);
          if(s){
            swal("Approved!", "User has been "+sts+"!", "success");
          }else{
            swal("Failed!", "Something is not correct!", "failed");
          }
        };
        
      }
    });
  });

  const columns: GridColDef[] = [
    {
      field: 'sn',
      headerName: 'Id',
      width: 80,
    },
    {
      field: 'name',
      headerName: 'Name',
      width: 150,
      editable: false,
      renderCell: (params: GridRenderCellParams) => {
        return <Tooltip title=''><a onClick={()=>{navigate("/userProfile?key="+params.row.id,"forward","push")}}>{params.row.name}</a></Tooltip>;
      }
    },
    {
      field: 'firmName',
      headerName: 'Firm Name',
      width: 150,
      editable: false,
      renderCell: (params: GridRenderCellParams) => {
        return <Tooltip title={params.row.firmName}><p className="firmName">{params.row.firmName}</p></Tooltip>;
      },
    },
    {
      field: 'number',
      headerName: 'Phone',
      width: 150,
      editable: false,
      renderCell: (params: GridRenderCellParams) => {
        return <Tooltip title={params.row.number}><p>{params.row.number}</p></Tooltip>;
      },
    },
    {
      field: 'gst',
      headerName: 'GST',
      width: 150,
      editable: false,
      renderCell: (params: GridRenderCellParams) => {
        return <Tooltip title={params.row.gst}><p className="gst">{params.row.gst}</p></Tooltip>;
      }
    },
    {
      field: 'accountType',
      headerName: 'Acc. Type',
      width: 150,
      editable: false,
      renderCell: (params: GridRenderCellParams) => {
        return <Tooltip title={params.row.customerType ? params.row.customerType : ''}><p>{params.row.customerType ? params.row.customerType : ''}</p></Tooltip>;
      }
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 120,
      editable: false,
      renderCell: (params: GridRenderCellParams) => {
        var status = params.row.status ? params.row.status : 'inactive';
        return <Tooltip title={params.row.status}><p style={{color: params.row.status=='active' ? 'var(--ion-color-success)' : 'var(--ion-color-danger)'}}>{params.row.status}</p></Tooltip>;
      }
    },
    {
      field: 'city',
      headerName: 'city',
      width: 120,
      editable: false,
      renderCell: (params: GridRenderCellParams) => {
        return <Tooltip title={params.row.address.city}><p>{params.row.address.city}</p></Tooltip>;
      },
    },
    {
      field: 'approval',
      headerName: 'Approval',
      width: 150,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => {
        return <Button style={{margin: '0px', padding: '2px',}} variant="contained" className={landscape ? classes.submit : classes.submit2} onClick={()=>approveClick(params.row)}>Show</Button>;
      }
  },
  ]

  const searchevent = (e:any) => { 
    console.log(e.detail.value);
    const lowercasedInput = e.detail.value.toLowerCase();
    const results = users?.filter((user) => {
      return user?.name?.toLowerCase().includes(lowercasedInput) || user?.firmName?.toLowerCase().includes(lowercasedInput) || user?.email?.toLowerCase().includes(lowercasedInput) || user?.gst?.toLowerCase().includes(lowercasedInput) || user?.role?.toLowerCase().includes(lowercasedInput) || user?.status?.toLowerCase().includes(lowercasedInput) || user?.address?.city?.toLowerCase().includes(lowercasedInput) || user?.address?.local?.toLowerCase().includes(lowercasedInput) || user?.number?.toLowerCase().includes(lowercasedInput);
    });
    setUserList(results);
  }

  return (
    <IonPage>
      {users  ? '' : <CustomLoading />}
         <SideMenu3 title="Customers" searchevent={searchevent} /> 
          <div className={classes.main} >
            <Grid container justify="space-evenly">
            <div style={{ height: height * 88 / 100, width: '100%' }}>
                  {userList ? <DataGrid
                    rows={userList}
                    columns={columns}
                    pageSize={100}
                    density = 'compact'
                    rowsPerPageOptions={[50]}
                    disableSelectionOnClick
                  />  : users && users.length>0 ? 
                    <DataGrid
                    rows={users}
                    columns={columns}
                    pageSize={100}
                    density = 'compact'
                    rowsPerPageOptions={[50]}
                    disableSelectionOnClick
                  /> 
                  : <IonBadge color="primary">No records available</IonBadge>}
                </div>
            </Grid>
          </div>
    </IonPage>
) ;
};

const useStyles = makeStyles((theme) => ({
  main: {
    marginLeft: '60px', 
    marginTop: '60px', 
    padding: '10px', 
    position: 'relative',
    height: '-webkit-fill-available', 
    overflow: 'auto', 
    width: '-webkit-fill-available',
},
  container: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignContent: 'flex-start',
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
  submit: {
    color: 'var(--ion-text-color-highlight)',
    backgroundColor: 'var(--ion-color-primary)',
    fontWeight: 'bold',
  },
  submit2: {
      color: 'var(--ion-text-color-highlight)',
      backgroundColor: 'var(--ion-color-primary)',
      fontWeight: 'bold',
  },
}));

export default UserHiddenListPage;