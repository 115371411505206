import React, { useEffect, useState } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { green, red } from '@material-ui/core/colors';
import settings from '../../config/settings';
import FirestoreService from '../../services/FirestoreService';
import ShareIcon from '@mui/icons-material/Share';
import GlobalService from '../../services/GlobalService';
import ProductCatalogCanvas from './ProductCatelogCanvas';
import { CustomDialog, useDialog } from 'react-st-modal';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { IonBadge } from '@ionic/react';
import { Color } from '../color/Color';
import { Size } from '../size/Size';
import BrandComponent from '../../components/common/BrandComponent';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      width: '100%',
    },
    ionCard:{
      width: '100%',
    },
    paper: {
      padding: theme.spacing(2),
      margin: '5px',
      width: '100%',
    },
    media: {
      height: 0,
      paddingTop: '56.25%', // 16:9
    },
    avatar: {
      backgroundColor: red[500],
    },
    button:{
      backgroundColor: 'var(--ion-color-primary)',
    },
    large: {
      width: 'auto',
      height: '200px',
      maxHeight: '250px',
      maxWidth: '300px',
      objectFit: 'scale-down',
      padding: '10px',
    },
    box: {
      
    },
    img: {
      margin: 'auto',
      display: 'block',
      maxWidth: '100%',
      maxHeight: '100%',
    },
    share: {
       position: 'absolute', 
       bottom: '20px', 
       right: '20px',
    }
  }),
);

function ProductCanvas({product, image, imageDetail}:any) {
  const dialog = useDialog();

  const [value, setValue] = useState();

  const popupEvent = () =>{
    dialog.close(value);
  }
  return (
    <div style={{textAlign: 'center'}}>
      <ProductCatalogCanvas product={product} imageDetail={imageDetail} image={image} popup={true} popupEvent={popupEvent} />
      <Button variant="contained" color="primary" onClick={()=>{
        var canvas:any = document.getElementById("canvas"+product.key);
        var dataURL = canvas.toDataURL("image/jpeg");
      }}  endIcon={<ShareIcon />}>
                Share
              </Button>
    </div>
  );
}

export interface Dimension{
  width: any,
  height: any,
}

export default function ProductCard({product, removeClick, editClick, brands}:any) {
  const classes = useStyles();
  var brand:any = brands.find((e:any) => e.name === product.brand)
  return (
    <div className={classes.root}>
      <Card sx={{ maxWidth: 345 }}>
        <CardMedia onClick={async () => {GlobalService.viewImage(product.url ? product.url : '', product.name, product.name)}}
          component="img"
          height="140"
          image={product.thumbnail ? product.thumbnail : product.url ? product.url : settings.imageUploadPlaceholder} 
          alt={product.name}
        />
        <CardContent>
        <Typography  style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} variant="h5">
            {brand && brand.name ? <BrandComponent titleSize="21px" subTitleSize="16px" logoSize="50px" height="50px" show={true} size1={3} size2={9} size1m={4} size2m={8} size1sm={4} size2sm={8} key={'brand'+product.key} brand={brand} /> : 
            <b style={{color: 'var(--ion-color-secondary)'}}>{product.brand}</b>}
          </Typography>
          <Typography style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} gutterBottom variant="h6" component="div">
              {product.name} {product.code ? ' | '+product.code : ''}
          </Typography>
          <Typography  style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', color: 'var(--ion-color-secondary)' }} variant="subtitle1">
             <b> {product.category1} {product.category2 ? ' -> '+product.category2 : ''} {product.category3 ? ' -> '+product.category3 : ''} </b>
          </Typography>
          <Typography  style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} variant="subtitle1" color="secondary">
            {product.user?.firmName ? product.user?.name+' | '+product.user?.firmName : product.user?.name}
          </Typography>
          <Typography style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} variant="subtitle2" color="danger">
            MRP: &#8377; {product.mrp} | WSP: &#8377; {product.wsp}
          </Typography>
          <Typography style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}  variant="body2" color="text.secondary">
            {product.description ? product.description : 'NA'}
          </Typography>
          <Typography style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', marginTop: '10px', minHeight: '20px', }}  variant="body2" color="text.secondary">
            {product.sizes && product.sizes.length > 0 ? product.sizes.map((item:Size,index:number)=>{
              return (
                <IonBadge color="secondary" style={{marginRight: '5px'}} key={item.name}>{item.name} {item.quantity} Pcs.</IonBadge>
              )
            }): ''}
            {product.colors && product.colors.length > 0 ? product.colors.map((item:Color,index:number)=>{
              return (
                <IonBadge color="secondary" style={{marginRight: '5px'}} key={item.id}>{item.name}</IonBadge>
              )
            }): ''}
            {product.patterns && product.patterns.length > 0 ? product.patterns.map((item:string,index:number)=>{
              return (
                <IonBadge color="secondary" style={{marginRight: '5px'}} key={item}>{item}</IonBadge>
              )
            }): ''}
          </Typography>
        </CardContent>
        <CardActions>
        <Button style={{backgroundColor: 'var(--ion-color-primary)', color: 'var(--ion-color-primary-contrast)', marginLeft: '5px', marginRight: '5px'}} onClick={editClick}>View</Button>
            <Button style={{backgroundColor: 'var(--ion-color-primary)', color: 'var(--ion-color-primary-contrast)', marginLeft: '5px', marginRight: '5px'}} onClick={removeClick}>Remove</Button>
        </CardActions>
      </Card>
    </div>
  );
}