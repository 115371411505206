import React from 'react'
import { IonSearchbar } from '@ionic/react';
import { IonInput} from '@ionic/react';
import './SearchStyle.css';

function Search({searchevent, placeholder, keypressevent}:any) {
    return (
        <div style={{width: '100%'}}>
            <IonInput style={{ marginLeft: '10px', border: '2px solid white', borderRadius: '25px',}} onKeyPress={keypressevent} placeholder={placeholder} onIonChange={searchevent}></IonInput>
        </div>
    )
}

Search.defaultProps = {
    placeholder: 'Search',
}

export default Search
