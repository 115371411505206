import React, { useContext, useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import CustomTextInput from '../../components/common/CustomTextInput';
import { IonItem, IonLabel, IonRow, IonToggle, NavContext } from '@ionic/react';
import GlobalService from '../../services/GlobalService';
import settings from '../../config/settings';
import { db } from '../../App';
import firebase from 'firebase/app';import 'firebase/auth';
import CustomButton from '../../components/common/CustomButton';
import { Avatar, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import NativeApi from '../../services/NativeApi';
import FirestoreService from '../../services/FirestoreService';
import CustomLoading from '../../components/loading/CustomLoading';
import { Notification } from './Notification';
import { Checkbox } from '@mui/material';
import NotificationService from '../../services/NotificationService';


export default function NotificationForm({popup, popupEvent}:any) {
  const classes = useStyles();
  let { height, width } = useWindowDimensions();
  let landscape = height > 500;
  let key = new URLSearchParams(window.location.search).get("key");
  let uid = "";
  const [image, setImageData] = React.useState<string>(settings.imageUploadPlaceholder);
  const { navigate } = useContext(NavContext);
  const [notification, setNotification] = React.useState<Notification>({
      title: "",
      body: "",
  }); 
  const [loading, setLoading] = useState<Boolean>();
  useEffect(() => {
   
    // Unsubscribe from events when no longer in use
    return ;
  }, []);
  
async function submitForm() {
  if(notification.title && notification.body){
    setLoading(true);
    var res = await NotificationService.sendNotificationForSubscribe(notification); 
    GlobalService.showtoast('notification',"Notification Sent","success",undefined,undefined);
    setLoading(false);
  }else{
      GlobalService.showtoast("notify","Please enter valid details","error",undefined,undefined)
  }
}

const setData = (event:any) =>{
    const { name, value } = event.target;
    setNotification({ ...notification, [name]: value});
}

 async function uploadImage()  {
  var imageurl = NativeApi.takePicture(0, 0, 0);
  imageurl.then(async (img) => {
    setLoading(true);
      var format = ".jpeg";
      var str = img.split("base64")[0];
      if(str.includes("png")){
        format = ".png";
      };
      var fileName = GlobalService.getCurrentDate().getTime()+"";
      var path = "notification/"+fileName;
      var res = await FirestoreService.uploadPicture(img, path+format);
      if (res) {
        var blob = await FirestoreService.uploadString(img, path+".txt");
        if (blob) {
            var url = path+format;
            notification.image = settings.firestoreUrlPrefix+url.replaceAll("/","%2F")+settings.firestoreUrlSuffix;
            setImageData(img);
            setNotification({...notification})
            setLoading(false);
            GlobalService.showtoast("image", 'Image Uploaded', 'success', undefined, undefined);
        }else {
          setLoading(false);
          GlobalService.showtoast("image", 'Upload failed', 'error', undefined, undefined);
        }
      } else {
        setLoading(false);
        GlobalService.showtoast("image", 'Upload failed', 'error', undefined, undefined);
      }
  })
}

  return (
    <Container component="main">
      <CssBaseline />
      {loading ? <CustomLoading /> : ''}
      <div className={classes.paper} style={{marginTop: '0px'}}>
        
        <form className={classes.form}  noValidate>
        <Grid container justify="space-evenly" >    
            <Grid container spacing={3}>
                <Grid item sm={12} xs={12} style={{marginTop: '20px'}}>
                <Grid container justify="space-evenly" >
                      <Grid item xs={9} sm={8} lg={6}>
                        <a onClick={async () => {GlobalService.viewImage(image, undefined, undefined)}}>
                        <Avatar variant="square" src={image} alt="Product" className={classes.large}>

                        </Avatar>
                        </a>
                      </Grid>
                      <Grid item xs={3} sm={4} lg={6}>
                        <Grid container justify="space-evenly" >
                          <Grid item xs={12} sm={12} lg={12}>
                            <CustomButton title="Upload" onClick={uploadImage} />
                          </Grid>
                          {/* <Grid item xs={12} sm={12} lg={12}>
                              <CustomButton title="Remove" onClick={removeImage} />
                          </Grid> */}
                        </Grid>
                      </Grid>
                    </Grid>
                </Grid>
                <Grid item sm={12} xs={12} style={{marginTop: '20px'}}>
                  <CustomTextInput error={notification.errorShow && !notification.title } 
                        errorText="Please enter title" required={true} 
                        label="Title" name="title" autoComplete="title" onchange={setData} />
                </Grid>
                <Grid item sm={12} xs={12} style={{marginTop: '20px'}}>
                  <CustomTextInput error={notification.errorShow && !notification.body } 
                        errorText="Please enter body" required={true} 
                        label="Body" name="body" autoComplete="body" onchange={setData} />
                </Grid>
                <Grid item sm={12} xs={12} style={{marginTop: '20px'}}>
                  <CustomTextInput 
                        label="Click Action" name="clickAction" autoComplete="clickAction" onchange={setData} />
                </Grid>
                <Grid item sm={12} xs={12} style={{marginTop: '20px'}}>
                    <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="Customer"
                        name="radio-buttons-group"
                    >
                        <FormControlLabel value="Customer" name="subscriber" control={<Radio />} label="Customer" onChange={setData} />
                        <FormControlLabel value="Supplier" name="subscriber" control={<Radio />} label="Supplier" onChange={setData} />
                    </RadioGroup>
                </Grid>
            </Grid>
            <Grid item xs={12}>
            <Button
                fullWidth
                variant="contained"
                className={landscape ? classes.submit : classes.submit2}
                onClick={submitForm}
            >
               Send
            </Button>
            </Grid>
        </Grid>
        </form>
      </div>
    </Container>
  );
}


const useStyles = makeStyles((theme) => ({
    loginHeading: {
        color: 'var(--ion-text-color)',
    },
    container: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'center',
        alignContent: 'flex-start',
    },
    paper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      position: 'fixed',
    },
    small: {
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
    large: {
      width: 'auto',
      height: '200px',
      maxHeight: '250px',
      maxWidth: '300px',
      objectFit: 'scale-down',
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      color: 'var(--ion-text-color-highlight)',
      backgroundColor: 'var(--ion-color-primary)',
      margin: theme.spacing(3, 0, 2),
      fontWeight: 'bold',
    },
    submit2: {
        color: 'var(--ion-text-color-highlight)',
        backgroundColor: 'var(--ion-color-primary)',
        fontWeight: 'bold',
        margin: 0,
    },
    link: {
        color: 'var(--ion-color-primary)',
    },
  }));