import { Avatar, Divider, List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import MenuCard from "./SideMenuItemCard";



  const SideMenuItem = ({data}:any) => (
    <div>
        <List>
            {data.list.map((data:any, index:any) => (
                <MenuCard data={data} key={data.key} />
            ))}
        </List>
        <Divider />
    </div>
  );

  SideMenuItem.defaultProps = {
    backgroundColor: 'var(--ion-text-color)',
    color: 'var(--ion-text-color-contrast)',
    iconSize: 56,
    icon: 'telegram',
    text: 'sample',
    count: '0/0',
    page: "Home",
}
  
  export default SideMenuItem;
  