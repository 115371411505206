import { Button, Grid, makeStyles } from '@material-ui/core';
import { IonBadge, IonPage, NavContext } from '@ionic/react';
import { useContext, useEffect, useState } from 'react';
import SideMenu3 from '../../components/menu/SideMenu3';
import swal from 'sweetalert';
import { UseAuthInit } from '../../hooks/firebase/Auth';
import { useProductInit } from './ProductDataByUid';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import ProductCard from './ProductCard';
import CustomFab from '../../components/fab/CustomFab';
import { Product } from './Product';
import ProductService from './ProductService';
import { useBrandInit } from '../brand/BrandData';
import FirestoreService from '../../services/FirestoreService';
import settings from '../../config/settings';
import firebase from 'firebase';
import { db } from '../../App';

const ProductListPageUser: React.FC = () => {
  const classes = useStyles();
  const { navigate } = useContext(NavContext);
  const { auth } = UseAuthInit();
  const [files, setFiles] = useState<File[]>();
  const id = auth?.userId;
  let uid = new URLSearchParams(window.location.search).get("uid");
  const [prods, setProducts] = useState<Product[]>();
  const [productList, setProductList] = useState<Product[]>();
  let { height, width } = useWindowDimensions();
  const {brands} = useBrandInit();
  const [val, setVal] = useState<boolean>();

//   if(!val && products && products.length>0){
//     setVal(true);
//     readFiles();
//   }

  useEffect(()=>{
    return firebase.auth().onAuthStateChanged(async (firebaseUser:any) => {
        var prods:Product[] = await ProductService.getUserProducts(uid? uid : '');
        setProducts(prods);
        // updateProducts(prods);
        // readFiles(prods);
    })
  }, []);  

  async function updateProducts(products:Product[]){
    if(products && products.length>0){
        for(var i=0; i <products.length ; i++){
            var product:any = products[i];
            var fullName = product.image.split('/')[3];
            var fileName = fullName.split(".")[0];
            var thumbnail = product.url.split(fileName)[0]+fileName+settings.thumbnailSize+product.url.split(fileName)[1];
            console.log(thumbnail)
            products[i].thumbnail = thumbnail;
        }
        console.log(products)
        await db.collection("product").doc(uid ? uid : '').set({
            products,
            status: "active"
        })
        console.log("updated")
    }
  }

  async function readFiles(products:Product[]){
    if(products && products.length>0){
      for(var product of products){
        if(product.blobPath && !product.thumbnail){
            getFiles(product);
        }
      }
    }
  }

  async function getFiles(product:Product){
    if(product.url && product.blobPath && product.image){
      var link = await FirestoreService.getPicture(product.blobPath);
      fetch(link)
      .then(response => response.text())
      .then(async (data) => {
        // Do something with your data
          var res = await FirestoreService.uploadPicture(data, product.image ? product.image : '');
        //   if(res && product.url && product.blobPath && product.image){
        //     var fullName = product.image.split('/')[3];
        //     var fileName = fullName.split(".")[0];
        //     var thumbnail = product.url.split(fileName)[0]+fileName+settings.thumbnailSize+product.url.split(fileName)[1];
        //     console.log(thumbnail)
        //     product.thumbnail = thumbnail;
        //     return product;
            // var up = await ProductService.addProductData(product, product.user?.id);
        //   }
      });
    }
  }



  const removeClick = (item:Product) => {
    swal({
      title: item.name,
      text: "Are you sure that you want to delete this product ?",
      icon: "warning",
      dangerMode: true,
      buttons: ["cancel","confirm"],
    })
    .then(async (willDelete) => {
      if (willDelete && prods) {
        console.log(item);
        var newProducts = prods.filter(function( obj ) {
            return obj.key !== item.key;
        });
        console.log(newProducts);
        if(item.user && item.user.id){
          let rs = await ProductService.updateProductData(newProducts, item.user.id);
          console.log(rs);
          if(rs){
            swal("Deleted!", "Product has been deleted!", "success");
          }else{
            swal("Failed!", "Something is not correct!", "failed");
          }
        }
        
      }
    });
  }

  const searchevent = (e:any) => { 
    const lowercasedInput = e.detail.value.toLowerCase();
    const results = prods?.filter((product) => {
      return product.name?.toLowerCase().includes(lowercasedInput) || product.code?.toLowerCase().includes(lowercasedInput) || product.description?.toLowerCase().includes(lowercasedInput) || product.category1?.toLowerCase().includes(lowercasedInput) || product.category2?.toLowerCase().includes(lowercasedInput) || product.category3?.toLowerCase().includes(lowercasedInput) || product.brand?.toLowerCase().includes(lowercasedInput) || product.user?.name?.toLowerCase().includes(lowercasedInput) || product.user?.firmName?.toLowerCase().includes(lowercasedInput);
    });
    setProductList(results);
  }

  return (
    <IonPage>
         <SideMenu3 title="Product" searchevent={searchevent} /> 
          {brands && brands.length>0 ? <div className={classes.main} >
            <Grid container spacing={1}>
                {
                  productList ? productList.map((item, index)=>{
                    return (
                      <Grid item key={item.key} lg={3} sm={6} xs={12} >
                        <ProductCard brands={brands} key={item.key} product={item} editClick={()=>{navigate("/addProduct?key="+item.key+"&uid="+item.user?.id,"forward","push")}} removeClick={()=>{removeClick(item)}} ></ProductCard>
                      </Grid>
                    )
                  }) : prods && prods.length >0 ? prods.map((item, index)=>{
                    return (
                      <Grid item key={item.key} lg={3} sm={6} xs={12} >
                        <ProductCard brands={brands} key={item.key} product={item} editClick={()=>{navigate("/addProduct?key="+item.key+"&uid="+item.user?.id,"forward","push")}} removeClick={()=>{removeClick(item)}} ></ProductCard>
                      </Grid>
                    )
                  }) :<IonBadge>No records found</IonBadge>
                }
            </Grid>
          </div> : ''}
        <CustomFab onclick={()=>{
          navigate("/addProduct","forward","push")
        }} />
    </IonPage>
) ;
};

const useStyles = makeStyles((theme) => ({
  main: {
    marginLeft: '60px', 
    marginTop: '60px', 
    padding: '10px', 
    position: 'relative',
    height: '-webkit-fill-available', 
    overflow: 'auto', 
    width: '-webkit-fill-available',
},
  container: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignContent: 'flex-start',
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
}));

export default ProductListPageUser;